import { Dimensions, StyleSheet } from 'react-native';

import { theme } from '../../styles/theme';

const windowWidth = Dimensions.get('window').width;

export default StyleSheet.create({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 16,
    paddingHorizontal: 16,
  },

  title: {
    ...theme.typography.title1,
    fontSize: 32,
    marginTop: 64,
    color: theme.palette.green[150],
  },

  subtitle: {
    ...theme.typography.title6,
    marginTop: 16,
    fontSize: 20,
    color: theme.palette.green[150],
  },

  description: {
    ...theme.typography.title6,
    marginTop: 24,
    fontSize: 16,
    color: theme.palette.green[150],
  },

  buttonsContainer: {
    marginTop: 48,
  },

  image: {
    marginTop: 32,
    maxWidth: '100%',
    height: 350,
    objectFit: 'cover',
    borderRadius: 16,
  },

  copyrightText: {
    ...theme.typography.title6,
    marginTop: 32,
    fontSize: 13,
    color: theme.palette.green[150],
    opacity: 0.6,
  },
});
