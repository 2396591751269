import React from 'react';

import { Image, Pressable, ScrollView, Text, View } from 'react-native';

import { BackIcon } from '../../icons';
import { theme } from '../../styles/theme';
import Button from '../button/Button';
import styles from './EducationCard.styles';

export interface EducationCardProps {
  title: string;
  subTitle?: string;
  paragraphs: string[];
  backdropImage: string;
  nextButtonText?: string;
  onPressNext?: () => void;
  onPressBack?: () => void;
}

const EducationCard = ({
  title,
  subTitle,
  paragraphs,
  backdropImage,
  nextButtonText,
  onPressNext,
  onPressBack,
}: EducationCardProps) => {
  const goBack = () => {
    console.log('goback');
  };

  return (
    <View style={styles.container}>
      <Image style={styles.image} source={{ uri: backdropImage }} />

      <Pressable
        onPress={() => (onPressBack ? onPressBack() : goBack)}
        style={styles.backContainer}
      >
        <BackIcon color={theme.palette.green[100]} size={24} />
      </Pressable>

      <View style={styles.contentContainer}>
        <ScrollView>
          <View style={styles.contentTextContainer}>
            <Text style={styles.overline}>Education</Text>
            <Text style={styles.title}>{title}</Text>
            {subTitle && <Text style={styles.subTitle}>{subTitle}</Text>}

            {paragraphs.map((para, idx) => (
              <Text key={idx} style={styles.paragraph}>
                {para}
              </Text>
            ))}
          </View>

          {nextButtonText && onPressNext && (
            <Button
              fullWidth
              color="white"
              label={nextButtonText}
              onPress={onPressNext}
            />
          )}
        </ScrollView>
      </View>
    </View>
  );
};

export default EducationCard;
