import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { api } from '../../../api';
import { PromotionCode } from '../../../types/payments';
import { queryKeys } from '../../../utils';

type useGetValidatePromoCodeArgs = {
  queryKey: string;
  promoCode: string;
};

export default function useGetValidatePromoCode({
  promoCode = '',
  queryKey = '',
}: useGetValidatePromoCodeArgs): UseQueryResult<PromotionCode, AxiosError> {
  return useQuery({
    queryKey: queryKeys.payments.validatePromoCode(queryKey),
    queryFn: () =>
      api
        .get('/payments/validate-promo-code', {
          params: {
            promoCode,
          },
        })
        .then((res) => res.data),
    enabled: !!queryKey, // Only run the query if queryKey is not null
    retry: false,
  });
}
