import { StyleSheet } from 'react-native';

import { theme } from '../../styles/theme';

export default StyleSheet.create({
  loadingContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
