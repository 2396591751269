import { StyleSheet } from 'react-native';

import { theme } from '../../styles/theme';

export default StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    paddingVertical: 12,
    paddingHorizontal: 32,
    borderRadius: 4,
    elevation: 3,
  },
  primaryColor: {
    backgroundColor: theme.palette.green[100],
    color: theme.palette.white[100],
  },
  whiteColor: {
    backgroundColor: theme.palette.white[100],
    color: theme.palette.green[100],
  },
  fullWidth: {
    width: '100%',
  },
  disabled: {
    opacity: 0.2,
  },

  icon: {
    marginRight: 8,
  },
  text: {
    ...theme.typography.title6,
    fontSize: 14,
  },
  blackText: {
    color: theme.palette.black[100],
  },
  whiteText: {
    color: theme.palette.white[100],
  },

  loadingIndicator: {
    marginLeft: 15,
  },
});
