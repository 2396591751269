import { getCurrencyCode } from './currencyMap';

export function formatMinutesTime(minutes: number): string {
  if (minutes < 60) {
    return `${minutes}m`;
  }

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  return `${hours}h ${remainingMinutes}m`;
}

export function capitalizeFirstLetter(string: string): string {
  if (!string) return '';

  return string.charAt(0).toUpperCase() + string.slice(1);
}

type FormatCurrencyArgs = {
  amount: number;
  countryCode?: string;
  currency?: string;
};

export const formatCurrency = ({
  amount,
  countryCode = 'UK',
}: FormatCurrencyArgs) => {
  const currency = getCurrencyCode();

  return Intl.NumberFormat(`en-${countryCode}`, {
    style: 'currency',
    currency: currency.toUpperCase(),
  }).format(amount / 100);
};
