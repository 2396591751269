import { ReactNode } from 'react';

import { ScrollView, Text, TouchableOpacity, View } from 'react-native';

import { ProgressBars } from '../../../../components';
import { BackIcon } from '../../../../icons';
import { theme } from '../../../../styles/theme';
import styles from './QuestionLayoutWrapper.styles';

interface QuestionLayoutWrapperProps {
  title?: string;
  subtitle?: string;
  currentStep: number;
  totalSteps: number;
  displayProgressBars: boolean;
  removePadding?: boolean;
  onPressBack?: (() => void) | null;
  children: ReactNode;
}

export default function QuestionLayoutWrapper({
  title,
  subtitle,
  currentStep,
  totalSteps,
  displayProgressBars = false,
  removePadding = false,
  onPressBack,
  children,
}: QuestionLayoutWrapperProps) {
  return (
    <View style={styles.container}>
      <ScrollView contentContainerStyle={styles.scrollContainerContent}>
        <View style={styles.wrapperContent}>
          {!onPressBack && <View style={styles.firstQuestionPadding} />}
          {onPressBack && (
            <TouchableOpacity
              onPress={onPressBack}
              style={styles.arrowBackIcon}
            >
              <BackIcon color={theme.palette.white[100]} size={24} />
            </TouchableOpacity>
          )}

          {displayProgressBars && (
            <ProgressBars currentStep={currentStep} totalSteps={totalSteps} />
          )}

          {title && <Text style={styles.title}>{title}</Text>}
          <View style={styles.subTitleContainer}>
            {subtitle && <Text style={styles.subtitle}>{subtitle}</Text>}
          </View>
        </View>

        <View
          style={{
            paddingHorizontal: removePadding ? 0 : 16,
          }}
        >
          {children}
        </View>
      </ScrollView>
    </View>
  );
}
