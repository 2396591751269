import { Text, TouchableOpacity, View } from 'react-native';

import { CheckIcon } from '../../../../icons';
import { InputItemProps } from '../../types';
import styles from './CheckboxInputItem.styles';

export default function CheckboxInputItem({
  label,
  selected,
  onPress,
  accessibilityRole,
}: InputItemProps) {
  return (
    <TouchableOpacity
      onPress={onPress}
      accessibilityRole={accessibilityRole}
      accessibilityState={{ checked: selected }}
      style={{
        ...styles.containerBase,
        ...(selected ? styles.containerSelected : styles.containerUnselected),
      }}
    >
      <View
        style={{
          ...styles.checkboxBase,
          ...(selected ? styles.checkboxSelected : styles.checkboxUnselected),
        }}
      >
        {selected && <CheckIcon />}
      </View>

      <Text style={styles.text}>{label}</Text>
    </TouchableOpacity>
  );
}
