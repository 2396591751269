import {
  KeyboardAvoidingView,
  Platform,
  Text,
  TextInput,
  View,
} from 'react-native';

import Button from '../../../../components/button/Button';
import styles from './InputWithButton.styles';

interface InputWithButtonProps {
  text: string;
  setText: (promoCode: string) => void;
  buttonLabel: string;
  successText?: string;
  error: string;
  onButtonPress: () => void;
  placeholder: string;
}

export default function InputWithButton({
  text,
  setText,
  error,
  successText,
  buttonLabel,
  onButtonPress,
  placeholder,
}: InputWithButtonProps) {
  const outlineStyle = Platform.OS === 'web' && { outlineStyle: 'none' };

  return (
    // <KeyboardAvoidingView>
    <View style={styles.container}>
      <View style={styles.inputButtonContainer}>
        <TextInput
          value={text}
          onChangeText={setText}
          placeholder={placeholder}
          autoFocus={false}
          autoCorrect={false}
          scrollEnabled={false}
          focusable={false}
          style={{ ...styles.input, ...outlineStyle }}
        />

        <Button label={buttonLabel} onPress={onButtonPress} />
      </View>
      {error && <Text style={styles.errorText}>{error}</Text>}
      {successText && <Text style={styles.successText}>{successText}</Text>}
    </View>
    // </KeyboardAvoidingView>
  );
}
