import { StyleSheet } from 'react-native';

import { theme } from '../../styles/theme';

export default StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 16,
    backgroundColor: theme.palette.white[100],
  },

  textContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 500,
    maxWidth: '80%',
  },

  sadFaceContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 112,
    height: 112,
    backgroundColor: theme.palette.grey[20],
    borderRadius: 9999,
  },

  textTitle: {
    ...theme.typography.title3,
    textAlign: 'center',
    marginTop: 24,
  },

  textDescription: {
    ...theme.typography.title5,
    textAlign: 'center',
    opacity: 0.6,
    marginTop: 8,
  },
});
