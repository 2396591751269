import { Text, View } from 'react-native';

import Button from '../../../../components/button/Button';
import { EmotionSadIcon } from '../../../../icons';
import { theme } from '../../../../styles/theme';
import styles from './AbortFlowScreen.styles';

interface AbortFlowScreenProps {
  abortFlowReason: string;
  onPressBack: () => void;
}

export default function AbortFlowScreen({
  abortFlowReason,
  onPressBack,
}: AbortFlowScreenProps) {
  return (
    <View style={styles.container}>
      <View style={styles.rejectContent}>
        <View style={styles.rejectIllustrationContainer}>
          <EmotionSadIcon size={110} fill={theme.palette.black[100]} />
        </View>
        <Text style={styles.rejectReasonTitle}>
          Sorry, we cannot accept you at this stage
        </Text>
        <Text style={styles.rejectReasonText}>{abortFlowReason}</Text>
      </View>

      <Button fullWidth label="Back" onPress={onPressBack} />
    </View>
  );
}
