import { useContext } from 'react';

import { Navigate, Outlet } from 'react-router-dom';

import { UserContext } from '../../contexts';

const ProtectedRoute = () => {
  const { user } = useContext(UserContext);

  if (!user) {
    return <Navigate to="login" />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
