import { StyleSheet } from 'react-native';

import * as theme from '../../../../styles/theme';

export default StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    flexGrow: 1,
    backgroundColor: theme.theme.palette.green[100],
  },
  scrollContainerContent: {
    minHeight: '100%',
  },
  wrapperContent: {
    paddingHorizontal: 16,
    paddingTop: 16,
  },

  firstQuestionPadding: {
    paddingTop: 56,
    width: 10,
  },

  arrowBackIcon: {
    marginVertical: 16,
  },
  title: {
    ...theme.theme.typography.title1,
    color: theme.theme.palette.white[100],
    marginTop: 32,
  },
  subTitleContainer: {
    marginBottom: 24,
  },
  subtitle: {
    ...theme.theme.typography.title6,
    color: theme.theme.palette.white[100],
    opacity: 0.7,
    marginTop: 8,
  },
});
