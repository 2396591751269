// import path from 'path';

import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  InternalAxiosRequestConfig,
} from 'axios';

import { auth } from '../config/firebaseConfig';

const getAuthToken = async () => {
  const token = await auth.currentUser?.getIdToken();
  return token;
};

export class Api {
  token: string | null;
  axiosInstance: AxiosInstance;
  requiresAuthentication?: Boolean;

  constructor(requiresAuthentication: Boolean = true) {
    this.token = null;
    this.axiosInstance = axios.create({
      baseURL: process.env['REACT_APP_API_URL'],
    });

    this.requiresAuthentication = requiresAuthentication;

    if (this.requiresAuthentication) {
      this.axiosInstance.interceptors.request.use(
        this.authorizationInterceptor as any
      );
    }
  }

  authorizationInterceptor = async (
    config: InternalAxiosRequestConfig
  ): Promise<InternalAxiosRequestConfig | Boolean> => {
    const newConfig = { ...config };

    const token = await getAuthToken();

    if (token) {
      newConfig.headers.Authorization = `Bearer ${token}`;
      return newConfig;
    }

    return false;
  };

  get = async <TData>(url: string, config?: AxiosRequestConfig<any>) => {
    return this.axiosInstance.get<TData>(url, config);
  };

  post = async <TData>(
    url: string,
    data?: Record<string, any>,
    config?: AxiosRequestConfig<any>
  ) => {
    return this.axiosInstance.post<TData>(url, data, config);
  };

  patch = async <TData>(
    url: string,
    data?: Record<string, any>,
    config?: AxiosRequestConfig<any>
  ) => {
    return this.axiosInstance.patch<TData>(url, data, config);
  };

  put = async <TData>(
    url: string,
    data?: Record<string, any>,
    config?: AxiosRequestConfig<any>
  ) => {
    return this.axiosInstance.put<TData>(url, data, config);
  };

  delete = async (url: string, config?: AxiosRequestConfig<any>) => {
    return this.axiosInstance.delete(url, config);
  };
}

const api = new Api();
const unAuthenticatedApi = new Api(false);

export { api, unAuthenticatedApi };
