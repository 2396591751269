import { View } from 'react-native';

import { ReviveFullLogo } from '../../icons';
import { theme } from '../../styles/theme';
import { hexToRgbA } from '../../styles/utils';
import styles from './Navbar.styles';

export default function Navbar() {
  return (
    <View style={styles.container}>
      <a href="https://revivedna.com/">
        <ReviveFullLogo size={30} />
      </a>
    </View>
  );
}
