import { useContext, useEffect, useState } from 'react';

import { signInWithCustomToken } from 'firebase/auth';

import { LoadingSpinnerScreen, ProtectedRoute } from './components';

import { useGetPaymentPlans, useGetQuestionnaire } from './hooks/queries';
import { auth } from './hooks/useAuthentication/useAuthentication';

import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';

import { UserContext } from './contexts';
import userContext from './contexts/userContext';
import {
  DownloadAppScreen,
  OnboardingScreen,
  PaymentErrorScreen,
  PaymentPlanSelectionScreen,
  StartOnboardingScreen,
} from './screens';
import { AuthScreen } from './screens/auth';
import { SelectedPaymentPlan } from './screens/paymentPlanSelectionScreen/PaymentPlanSelectionScreen';

const QUESTIONNAIRE_TYPE = 'onboarding';

function App() {
  const { loading } = useContext(UserContext);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const authenticateUser = async () => {
      const customToken = searchParams.get('token');

      if (customToken) {
        try {
          // Verify the custom token
          await signInWithCustomToken(auth, customToken);
          // remove token from url
          searchParams.delete('token');
          setSearchParams(searchParams);
        } catch (error) {
          // setUser(undefined);
          console.error('Error authenticating user:', error);
        }
      }
    };

    authenticateUser();
  }, [searchParams, setSearchParams]);

  if (loading) {
    return <LoadingSpinnerScreen />;
  }

  return <AppRoutes />;
}

const AppRoutes = () => {
  const navigate = useNavigate();
  const { user } = useContext(userContext);

  const [promoCode, setPromoCode] = useState<string | null>(null);

  const [selectedPaymentPlan, setSelectedPaymentPlan] =
    useState<SelectedPaymentPlan>({});

  const questionnaireQuery = useGetQuestionnaire({
    questionnaireType: QUESTIONNAIRE_TYPE,
  });

  const { data: paymentPlansOptions } = useGetPaymentPlans();

  useEffect(() => {
    if (
      user?.isOnboardingQuestionnaireCompleted &&
      user?.isSubscriptionActive
    ) {
      navigate('/download');
    }
  }, [
    navigate,
    user?.isOnboardingQuestionnaireCompleted,
    user?.isSubscriptionActive,
  ]);

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [navigate, user]);

  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route path="/payment-error" element={<PaymentErrorScreen />} />
        <Route path="/download" element={<DownloadAppScreen />} />
        <Route
          path="/onboarding"
          element={
            <OnboardingScreen
              questionnaireQuery={questionnaireQuery}
              selectedPaymentPlan={selectedPaymentPlan}
              promoCode={promoCode}
            />
          }
        />
        <Route path="/start-onboarding" element={<StartOnboardingScreen />} />
        <Route
          path="/"
          element={
            <PaymentPlanSelectionScreen
              paymentPlansOptions={paymentPlansOptions}
              selectedPaymentPlan={selectedPaymentPlan}
              setSelectedPaymentPlan={setSelectedPaymentPlan}
              setPromoCode={setPromoCode}
            />
          }
        />
      </Route>
      <Route path="/login" Component={AuthScreen} />
    </Routes>
  );
};

export default App;
