import { ActivityIndicator, View } from 'react-native';

import { theme } from '../../styles/theme';
import styles from './LoadingSpinnerScreen.styles';

export default function LoadingSpinnerScreen() {
  return (
    <View style={styles.loadingContainer}>
      <ActivityIndicator size="large" color={theme.palette.black[100]} />
    </View>
  );
}
