const base = ['fitbit'] as const;

export default {
  getSteps: (filters: Record<string, string | number>) =>
    [...base, 'steps', filters] as const,

  getSleep: (filters: Record<string, string | number>) =>
    [...base, 'sleep', filters] as const,

  getSleepByDateRange: (filters: Record<string, string | number>) =>
    [...base, 'sleepByDateRange', filters] as const,

  getHeartRate: (filters: Record<string, string | number>) =>
    [...base, 'heartRate', filters] as const,

  getHeartRateByDateRange: (filters: Record<string, string | number>) =>
    [...base, 'heartRateByDateRange', filters] as const,
};
