const base = ['user'] as const;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getMyUser: () => [...base, 'get'] as const,
  createOrUpdate: () => [...base, 'save'] as const,
  getIngredientDetails: () => [...base, 'ingredientDetails'] as const,
  getIngredientOfTheWeek: () => [...base, 'ingredientOfTheWeek'] as const,
  getMySuperfoods: () => [...base, 'superfoods'] as const,
  mySubscriptionDetails: () => [...base, 'subscriptionDetails'] as const,
};
