export const typography = {
  title1: {
    fontFamily: 'Montserrat-SemiBold',
    fontSize: 24,
  },

  title2: {
    fontFamily: 'Montserrat-Bold',
    fontSize: 20,
  },

  title3: {
    fontFamily: 'Montserrat-Bold',
    fontSize: 16,
  },

  title4: {
    fontFamily: 'Montserrat-Bold',
    fontSize: 12,
  },

  title5: {
    fontFamily: 'Montserrat-SemiBold',
    fontSize: 12,
  },

  title6: {
    fontFamily: 'Montserrat-Medium',
    fontSize: 12,
  },

  title7: {
    fontFamily: 'Montserrat-Medium',
    fontSize: 10,
  },

  titleRegular: {
    fontFamily: 'Montserrat-Regular',
    fontSize: 12,
  },

  titleThin: {
    fontFamily: 'Montserrat-Thin',
    fontSize: 12,
  },
};
