export default function CheckIcon({ size = 18, color = "black", ...props }) {
  return (
    <svg width={size / 1.25} height={size} viewBox="0 0 10 8" fill="none">
      <path
        d="M9 1L3.5 6.5L1 4"
        stroke={color}
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
        {...props}
      />
    </svg>
  );
}
