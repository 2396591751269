import { useMapArrayWithFieldAsKey } from '../../../../hooks/utils';
import {
  EducationalCadType,
  Question,
  Questionnaire,
  QuestionnaireAnswers,
} from '../../../../types/questionnaire';

interface UseMemoQuestionsToShowArgs {
  questionnaire: Questionnaire | undefined;
  answers: QuestionnaireAnswers;
}

export type QuestionToShow =
  | { question: Question; educationalCard: null }
  | { question: null; educationalCard: EducationalCadType };

type UseMemoQuestionsToShowReturn = QuestionToShow[];

export default function useMemoQuestionsToShow({
  questionnaire,
  answers: answerIdsByQuestionId,
}: UseMemoQuestionsToShowArgs): UseMemoQuestionsToShowReturn {
  // Lookup table for questionDependencies by questionId
  const dependenciesByQuestionId = useMapArrayWithFieldAsKey(
    questionnaire?.questionDependencies || [],
    'fromQuestionId'
  );

  if (!questionnaire) {
    return [];
  }

  // The Question IDs to show or hide must be unique so we can use a Set for faster lookups.
  const questionIdsToHide: Set<string> = new Set();

  questionnaire.questions.forEach((question) => {
    // Get the dependencies for that question. If there are none, skip to next question.
    const dependency = dependenciesByQuestionId[question.id];
    if (!dependency) return;

    // Get the answers for that question. If there are none, skip to next question.
    const answers = answerIdsByQuestionId[question.id];
    if (!answers) return;

    // Iterate through the conditions of the dependency and add the question IDs to hide to the
    // set if the answers match the condition.
    dependency.conditions.forEach(
      ({ ifAnswerId, textEndsWith, hideQuestionIds }) => {
        // Hide the questionIf the answer is in the choiceIds array, either:
        // - There's no `textEndsWith`
        // - There's `textEndsWith` and the response text ends with the given string
        if (
          answers.choiceIds.includes(ifAnswerId) &&
          (!textEndsWith || answers.responseText.endsWith(textEndsWith))
        ) {
          hideQuestionIds.forEach((id) => questionIdsToHide.add(id));
        }
      }
    );
  });

  // The questions to show will be all the questions that are not in the Set of questions to hide.
  const filteredQuestions = questionnaire.questions.filter(
    (question) => !questionIdsToHide.has(question.id)
  );

  const questionsToShow: UseMemoQuestionsToShowReturn = [];
  filteredQuestions.forEach((question) => {
    questionsToShow.push({ question, educationalCard: null });

    const educationalCards = (questionnaire.educationalCards || []).find(
      (educationalCard) => educationalCard.afterQuestionId === question.id
    );

    if (educationalCards) {
      educationalCards.cards.forEach((educationalCard) => {
        questionsToShow.push({
          question: null,
          educationalCard: educationalCard.type,
        });
      });
    }
  });

  return questionsToShow;
}
