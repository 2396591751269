import { StyleSheet } from 'react-native';

import { theme } from '../../styles/theme';

export default StyleSheet.create({
  containerWithBackdrop: {
    flex: 1,
    justifyContent: 'flex-end',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },

  containerWithoutBackdrop: {
    flex: 1,
    justifyContent: 'flex-end',
  },

  container: {
    backgroundColor: 'white',
    padding: 16,
    paddingTop: 36,
    paddingBottom: 16,
    borderTopLeftRadius: theme.radius.xLarge,
    borderTopRightRadius: theme.radius.xLarge,
    alignItems: 'center',
    elevation: 10000,
  },
});
