import { StyleSheet } from 'react-native';

import { theme } from '../../styles/theme';
import { hexToRgbA } from '../../styles/utils';

export default StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    paddingBottom: 16,
  },

  absolutePositionedTop: {
    position: 'absolute',
    top: -2,
    right: 16,
    zIndex: 1010,
  },

  promoCodeInfoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 16,
  },

  underlinedText: {
    textDecorationLine: 'underline',
  },

  strikeThrough: {
    textDecorationLine: 'line-through',
  },

  scrollContainer: {
    paddingBottom: 70,
    paddingHorizontal: 16,
  },

  contentContainer: {
    rowGap: 4,
  },

  tabBarContainer: {
    marginTop: 16,
  },

  subscriptionContainer: {
    padding: 16,
    backgroundColor: theme.palette.green[70],
    borderRadius: 8,
    zIndex: 0,
  },

  divider: {
    height: 1,
    opacity: 0.2,
    width: '100%',
    backgroundColor: '#1E3E30',
    marginVertical: 16,
  },

  title: {
    ...theme.typography.title5,
    fontSize: 18,
    marginBottom: 8,
  },

  description: {
    ...theme.typography.titleRegular,
    fontSize: 13,
  },

  priceBold: {
    ...theme.typography.title6,
    fontSize: 18,
  },

  freeText: {
    ...theme.typography.title6,
    color: theme.palette.green[100],
    fontSize: 16,
  },

  labelContainerRow: {
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'center',
  },

  labelContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },

  freePriceContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 8,
  },

  orContainer: {
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100,
    width: '100%',
  },

  or: {
    position: 'absolute',
    paddingHorizontal: 32,
    paddingVertical: 8,
    backgroundColor: theme.palette.white[100],
    borderRadius: 100,
  },

  priceList: {
    flexDirection: 'row',
    marginVertical: 16,
    width: '100%',
    columnGap: 16,
  },

  plusCircleIconContainer: {
    backgroundColor: theme.palette.white[100],
    borderRadius: 100,
  },

  whatsIncludedContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 8,
    columnGap: 8,
  },

  footer: {
    bottom: 0,
    left: 0,
    padding: 16,
    backgroundColor: theme.palette.white[100],
    borderTopColor: hexToRgbA('#1E3E30', 0.2),
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  footerText: {
    ...theme.typography.title6,
    fontSize: 13,
  },

  footerBoldText: {
    ...theme.typography.title5,
    fontSize: 13,
  },
});
