export default function HappyIcon({ size = 18, color = "black", ...props }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        d="M8.2082 20.1644C11.5397 21.7056 15.6145 21.1111 18.3642 18.3605C21.8757 14.849 21.8815 9.1512 18.3642 5.63289C14.8527 2.12237 9.1481 2.12237 5.6366 5.63289C2.88695 8.38351 2.29149 12.4574 3.83366 15.7889C4.03118 16.2822 4.18491 16.6782 4.18491 17.0625C4.18491 18.1367 3.14868 19.4668 3.8395 20.1576C4.53032 20.8484 5.86039 19.8122 6.92872 19.8054C7.31208 19.8063 7.71587 19.9659 8.2082 20.1644Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        {...props}
      />
      <path
        d="M8.76953 9.20455V9.15298M8.72401 9C8.60001 9 8.49901 9.101 8.50001 9.225C8.50001 9.349 8.60101 9.45 8.72501 9.45C8.84901 9.45 8.95001 9.349 8.95001 9.225C8.95001 9.1 8.84901 9 8.72401 9Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        {...props}
      />
      <path
        d="M15.2307 9.20455V9.15298M15.224 9C15.1 9 14.999 9.101 15 9.225C15 9.349 15.101 9.45 15.225 9.45C15.349 9.45 15.45 9.349 15.45 9.225C15.45 9.1 15.349 9 15.224 9Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        {...props}
      />
      <path
        d="M9.30469 14.1406C9.96048 14.9949 10.9267 15.5271 11.9999 15.5271C13.0731 15.5271 14.0392 14.9949 14.695 14.1406"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        {...props}
      />
    </svg>
  );
}
