export const black = {
  100: '#000000',
};

export const white = {
  100: '#FFFFFF',
};

export const grey = {
  20: '#FAFAFA',
  50: '#D9D9D9',
};

export const green = {
  50: '#ECF3F0',
  70: '#EEF3E3',
  100: '#3E8364',
  150: '#1E3E30',
};

export const yellow = {
  100: '#EAE784',
  50: '#F0F2E6',
};

export const red = {
  100: '#AC2828',
  50: '#F2E6E6',
};

export const brown = {
  100: '#BD583F',
};

export const purple = {
  100: '#746DB9',
  50: '#E9E6F2',
};
