import { StyleSheet } from 'react-native';

import { theme } from '../../../../styles/theme';

export default StyleSheet.create({
  container: {
    padding: 4,
    width: '100%',
  },

  inputButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.green[70],
    borderRadius: 8,
  },

  input: {
    color: theme.palette.black[100],
    borderRadius: 4,
    paddingHorizontal: 16,
    borderColor: theme.palette.green[70],
    backgroundColor: theme.palette.green[70],
    fontSize: 16,
    width: '50%',
    flex: 1,
    ...theme.shadows.medium,
  },

  errorText: {
    ...theme.typography.titleRegular,
    color: theme.palette.red[100],
    marginTop: 4,
    fontSize: 13,
  },

  successText: {
    color: theme.palette.green[100],
    ...theme.typography.titleRegular,
    marginTop: 4,
    fontSize: 13,
  },
});
