const base = ['daily-goals'] as const;

export default {
  list: (filters: Record<string, string | number>) =>
    [...base, 'list', filters] as const,

  listAll: (dateString: string) => [...base, 'list-all', dateString] as const,

  update: () => [...base, 'update'] as const,

  stats: () => [...base, 'stats'] as const,

  getGoalDetails: (goalId: string) =>
    [...base, 'getGoalDetails', goalId] as const,
};
