import { StyleSheet } from 'react-native';

import { theme } from '../../../../styles/theme';
import { hexToRgbA } from '../../../../styles/utils';

export default StyleSheet.create({
  containerBase: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    rowGap: 8,
    paddingHorizontal: 24,
    paddingVertical: 32,
    borderWidth: 1,
    borderRadius: 4,
  },
  containerUnselected: {
    borderColor: hexToRgbA(theme.palette.white[100], 0.2),
  },
  containerSelected: {
    borderColor: theme.palette.yellow[100],
    backgroundColor: hexToRgbA(theme.palette.black[100], 0.05),
  },

  checkboxBase: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 19,
    height: 19,
  },
  checkboxUnselected: {
    borderWidth: 1,
    borderRadius: 2,
    borderColor: hexToRgbA(theme.palette.white[100], 0.2),
  },
  checkboxSelected: {
    backgroundColor: theme.palette.white[100],
  },

  text: {
    ...theme.typography.title6,
    color: theme.palette.white[100],
    fontSize: 14,
    flex: 1,
  },
});
