import { StyleSheet } from 'react-native';

import { theme } from '../../styles/theme';
import { hexToRgbA } from '../../styles/utils';

export default StyleSheet.create({
  container: {
    backgroundColor: theme.palette.white[100],
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderBottomColor: `1px solid ${hexToRgbA('#1E3E30', 0.2)}`,
    borderBottomWidth: 1,
  },
});
