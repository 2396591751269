export default function PlusCircleIcon({
  size = 18,
  color = "black",
  ...props
}) {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none">
      <g id="plus-circle (23) 1" clip-path="url(#clip0_802_19527)">
        <path
          id="Vector"
          d="M7.99967 14.6663C11.6816 14.6663 14.6663 11.6816 14.6663 7.99967C14.6663 4.31778 11.6816 1.33301 7.99967 1.33301C4.31778 1.33301 1.33301 4.31778 1.33301 7.99967C1.33301 11.6816 4.31778 14.6663 7.99967 14.6663Z"
          stroke={color}
          stroke-width="1"
          stroke-linecap="round"
          stroke-linejoin="round"
          {...props}
        />
        <path
          id="Vector_2"
          d="M8 5.33301V10.6663"
          stroke={color}
          stroke-width="1"
          stroke-linecap="round"
          stroke-linejoin="round"
          {...props}
        />
        <path
          id="Vector_3"
          d="M5.33301 8H10.6663"
          stroke={color}
          stroke-width="1"
          stroke-linecap="round"
          stroke-linejoin="round"
          {...props}
        />
      </g>
      <defs>
        <clipPath id="clip0_802_19527">
          <rect width={size} height={size} fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
}
