import { StyleSheet } from 'react-native';

import { theme } from '../../../styles/theme';
import { hexToRgbA } from '../../../styles/utils';

const baseStyleSheet = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 8,
  },
});

const invertedVariantStyleSheet = StyleSheet.create({
  label: {
    color: theme.palette.white[100],
    opacity: 0.7,
  },

  input: {
    color: theme.palette.white[100],
    borderRadius: 4,
    paddingHorizontal: 16,
    paddingTop: 16,
    paddingBottom: 8,
    borderWidth: 1,
    borderColor: hexToRgbA(theme.palette.white[100], 0.2),
    textAlignVertical: 'top',
  },
  inputFocused: {
    borderColor: theme.palette.yellow[100],
    backgroundColor: hexToRgbA(theme.palette.black[100], 0.05),
  },

  error: {
    color: theme.palette.red[50],
  },
});

const primaryVariantStyleSheet = StyleSheet.create({
  label: {
    color: theme.palette.black[100],
    opacity: 0.7,
  },

  input: {
    color: theme.palette.black[100],
    height: 48,
    borderRadius: 4,
    paddingHorizontal: 16,
    paddingTop: 16,
    paddingBottom: 8,
    borderWidth: 1,
    borderColor: '#F1F1F1',
    backgroundColor: theme.palette.white[100],
    ...theme.shadows.medium,
  },
  inputFocused: {
    borderColor: theme.palette.green[100],
    backgroundColor: theme.palette.white[100],
  },

  error: {
    color: theme.palette.red[50],
  },
});

const getStyles = (variant: 'primary' | 'inverted') => {
  if (variant === 'inverted') {
    return {
      ...baseStyleSheet,
      ...invertedVariantStyleSheet,
    };
  }

  return {
    ...baseStyleSheet,
    ...primaryVariantStyleSheet,
  };
};

export default getStyles;
