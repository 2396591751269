import { useState } from 'react';

import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

import { auth } from '../../../config/firebaseConfig';

const provider = new GoogleAuthProvider();

export default function useAuthWithGoogle() {
  const [error, setError] = useState<string | null>(null);

  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setError((error as any).message);
    }
  };

  return { error, signInWithGoogle };
}
