import { useCallback, useEffect, useState } from 'react';

import { getAuth, onAuthStateChanged, User } from 'firebase/auth';

import { app } from '../../config/firebaseConfig';
import { LoggedInUserResponse } from '../../types/auth';
import { useGetMyUser } from '../queries';

export const auth = getAuth(app);

const useAuthentication = () => {
  const [user, setUser] = useState<LoggedInUserResponse>();
  const [loading, setLoading] = useState(true);

  const { refetch } = useGetMyUser({ enabled: false });

  const handleAuthUserObserver = useCallback(
    async (authUser: User | null) => {
      if (authUser) {
        setLoading(true);
        const { data: fetchedUser } = await refetch();

        setUser(fetchedUser);
        setLoading(false);
        return;
      }

      setUser(undefined);
      setLoading(false);
    },
    [refetch]
  );

  useEffect(() => {
    const unsubscribeFromAuthStatusChanged = onAuthStateChanged(
      auth,
      handleAuthUserObserver
    );

    return unsubscribeFromAuthStatusChanged;
  }, [handleAuthUserObserver]);

  return {
    user,
    setUser,
    loading,
  };
};

export default useAuthentication;
