export default function GoogleIcon({ size = 18 }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        d="M22 12.2446C22 11.5556 21.9334 10.8445 21.8222 10.1777H12.1992V14.1114H17.7108C17.4885 15.3782 16.7551 16.4894 15.6662 17.2005L18.9553 19.7563C20.8888 17.9562 22 15.3337 22 12.2446Z"
        fill="#4280EF"
      />
      <path
        d="M12.1993 22.2004C14.955 22.2004 17.2663 21.2893 18.9554 19.7336L15.6662 17.2C14.755 17.8223 13.5772 18.1779 12.1993 18.1779C9.53238 18.1779 7.28775 16.3777 6.46546 13.9775L3.0874 16.5778C4.82088 20.0225 8.33228 22.2004 12.1993 22.2004Z"
        fill="#34A353"
      />
      <path
        d="M6.46538 13.9557C6.04313 12.689 6.04313 11.3111 6.46538 10.0443L3.08733 7.42188C1.64277 10.311 1.64277 13.7113 3.08733 16.5782L6.46538 13.9557Z"
        fill="#F6B704"
      />
      <path
        d="M12.1993 5.84399C13.6438 5.82177 15.0662 6.37737 16.1107 7.37745L19.022 4.44388C17.1775 2.7104 14.7328 1.77699 12.1993 1.79922C8.33228 1.79922 4.82088 3.97717 3.0874 7.4219L6.46546 10.0443C7.28775 7.62192 9.53238 5.84399 12.1993 5.84399Z"
        fill="#E54335"
      />
    </svg>
  );
}
