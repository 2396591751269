import EducationCard from '../../../../components/educationCard';

const image =
  'https://thumbor.forbes.com/thumbor/fit-in/900x510/https://www.forbes.com/health/wp-content/uploads/2022/01/foods_to_eat_to_lose_weight.jpeg';

interface ReduceChronicPainEducationScreenProps {
  onPressNext: () => void;
  onPressBack: () => void;
}

export default function ReduceChronicPainEducationScreen({
  onPressNext,
  onPressBack,
}: ReduceChronicPainEducationScreenProps) {
  return (
    <EducationCard
      title="How we help to reduce your chronic pain"
      subTitle="Pain signalling is really important as it is how our bodies tell us that there is something wrong."
      paragraphs={[
        'Pain signalling is governed by many different biochemical processes in the body, with the majority being within our immune system and our brain.',
        'By understanding the biochemistry at play we have developed an immune centric approach to pain management.',
        'By taking certain actions related to your acquired and innate immunity we can switch off this signalling using the nutrients your unique body needs to thrive.',
      ]}
      backdropImage={image}
      nextButtonText="Continue"
      onPressBack={onPressBack}
      onPressNext={onPressNext}
    />
  );
}
