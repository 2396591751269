import { Text, TouchableOpacity, View } from "react-native";

import { InputItemProps } from "../../types";
import styles from "./NumericInputItem.styles";

export default function NumericInputItem({
  label,
  index,
  selected,
  onPress,
  accessibilityRole,
}: InputItemProps) {
  return (
    <View style={styles.containerBase}>
      <TouchableOpacity
        onPress={onPress}
        accessibilityRole={accessibilityRole}
        accessibilityState={{ checked: selected }}
        style={{
          ...styles.checkboxBase,
          ...(selected ? styles.checkboxSelected : styles.checkboxUnselected),
        }}
      >
        <Text style={styles.number}>{index + 1}</Text>
      </TouchableOpacity>

      <Text style={styles.text}>{label}</Text>
    </View>
  );
}
