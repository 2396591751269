export default function ReviveFullLogo({ size = 36 }) {
  return (
    <svg
      width={(152 * size) / 36}
      height={size}
      viewBox="0 0 152 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8378 19.8063V16.5329C14.8378 16.5329 14.7191 15.4019 12.9089 14.2116C11.0688 12.9915 8.24961 11.325 2.58144 9.59903C2.58144 9.59903 0.0885842 8.9146 0.0291693 9.47994C-0.0301628 10.0454 -0.030162 12.9915 0.919483 13.6165C1.21623 13.795 2.37366 14.5688 4.03562 15.1639C6.64706 16.1161 10.3566 17.0982 12.8198 18.1993C13.9772 18.6754 14.6004 18.973 14.8378 19.8063Z"
        fill="#BCBCBC"
      />
      <path
        d="M0 16.5032V19.8064C0 19.8064 0.118746 20.9373 1.929 22.1277C3.76895 23.3478 6.58826 25.0142 12.2564 26.7402C12.2564 26.7402 14.7492 27.4247 14.8085 26.8593C14.8679 26.2939 14.8679 23.3478 13.9183 22.7228C13.6215 22.5442 12.4642 21.7705 10.8022 21.1754C8.19081 20.2231 4.4811 19.2411 2.01804 18.14C0.860688 17.6341 0.207786 17.3364 0 16.5032Z"
        fill="#BCBCBC"
      />
      <path
        d="M0 19.8065V16.5331C0 16.5331 0.118746 15.4022 1.929 14.2119C3.76895 12.9917 6.58826 11.3253 12.2564 9.59924C12.2564 9.59924 14.7492 8.91481 14.8085 9.48024C14.8679 10.0457 14.8679 12.9917 13.9183 13.6167C13.6215 13.7953 12.4642 14.569 10.8022 15.1641C8.19081 16.1164 4.4811 17.0984 2.01804 18.1996C0.860688 18.6757 0.207786 18.9733 0 19.8065Z"
        fill="#3E8364"
      />
      <path
        d="M14.8378 16.5034V19.8066C14.8378 19.8066 14.7191 20.9374 12.9089 22.1278C11.0688 23.3479 8.24961 25.0144 2.58144 26.7403C2.58144 26.7403 0.0885842 27.4248 0.0291693 26.8593C-0.0301628 26.294 -0.030162 23.3479 0.919483 22.7229C1.21623 22.5444 2.37366 21.7707 4.03562 21.1755C6.64706 20.2232 10.3566 19.2412 12.8198 18.1401C13.9772 17.6342 14.6004 17.3365 14.8378 16.5034Z"
        fill="#3E8364"
      />
      <path
        d="M42.7653 27L38.831 21.3983C38.3446 21.433 37.9625 21.4504 37.6846 21.4504H33.1771V27H30.5717V8.76175H37.6846C40.0469 8.76175 41.9054 9.32626 43.2603 10.4553C44.6151 11.5843 45.2926 13.1389 45.2926 15.1191C45.2926 16.526 44.9452 17.7245 44.2504 18.7146C43.5556 19.7047 42.5655 20.4255 41.2801 20.8772L45.6052 27H42.7653ZM37.6064 19.2357C39.2566 19.2357 40.5159 18.8796 41.3844 18.1675C42.2528 17.4553 42.6871 16.4392 42.6871 15.1191C42.6871 13.799 42.2528 12.7915 41.3844 12.0967C40.5159 11.3846 39.2566 11.0285 37.6064 11.0285H33.1771V19.2357H37.6064ZM67.9487 24.7332V27H54.7129V8.76175H67.5839V11.0285H57.3184V16.6302H66.4636V18.8449H57.3184V24.7332H67.9487ZM92.9652 8.76175L84.9665 27H82.387L74.3622 8.76175H77.1761L83.7419 23.7432L90.3598 8.76175H92.9652ZM100.502 8.76175H103.107V27H100.502V8.76175ZM129.442 8.76175L121.443 27H118.864L110.839 8.76175H113.653L120.218 23.7432L126.836 8.76175H129.442ZM150.214 24.7332V27H136.978V8.76175H149.849V11.0285H139.584V16.6302H148.729V18.8449H139.584V24.7332H150.214Z"
        fill="#3E8364"
      />
    </svg>
  );
}
