import { ActivityIndicator, Text, TouchableOpacity, View } from 'react-native';

import { theme } from '../../styles/theme';
import styles from './Button.styles';

interface ButtonProps {
  color?: 'primary' | 'white';
  label: string;
  fullWidth?: boolean;
  disabled?: boolean;
  icon?: React.ReactNode;
  onPress?: () => void;
  loading?: boolean;
  showLoadingSpinner?: boolean;
  allowDisabledClick?: boolean;
}

export default function Button({
  label,
  color = 'primary',
  fullWidth = false,
  disabled = false,
  allowDisabledClick = false,
  icon,
  onPress,
  loading = false,
  showLoadingSpinner = false,
}: ButtonProps) {
  return (
    <TouchableOpacity
      onPress={onPress}
      disabled={disabled}
      style={{
        ...styles.container,
        ...(color === 'primary' ? styles.primaryColor : styles.whiteColor),
        ...(fullWidth ? styles.fullWidth : {}),
        ...(disabled || allowDisabledClick ? styles.disabled : {}),
      }}
    >
      {icon && <View style={styles.icon}>{icon}</View>}
      <Text
        style={{
          ...styles.text,
          ...(color === 'primary' ? styles.whiteText : styles.blackText),
        }}
      >
        {label}
      </Text>
      {showLoadingSpinner && loading && (
        <ActivityIndicator
          color={
            color === 'primary'
              ? theme.palette.white[100]
              : theme.palette.black[100]
          }
          size={12}
          style={styles.loadingIndicator}
        />
      )}
    </TouchableOpacity>
  );
}
