import { StyleSheet } from 'react-native';

import { theme } from '../../../../styles/theme';

export default StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    paddingVertical: 12,
    paddingHorizontal: 12,
    borderRadius: 8,
    flex: 1,
    backgroundColor: theme.palette.white[100],
    borderColor: theme.palette.white[100],
    borderWidth: 1,
  },

  selectedContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    textAlign: 'center',
    paddingVertical: 12,
    paddingHorizontal: 12,
    borderRadius: 8,
    backgroundColor: theme.palette.green[50],
    borderColor: theme.palette.green[100],
    borderWidth: 1,
  },

  text: {
    ...theme.typography.title6,
    fontSize: 14,
    textAlign: 'center',
  },
});
