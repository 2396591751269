import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { Navbar } from './components';

import useAuthentication from './hooks/useAuthentication';

import * as Sentry from '@sentry/react';
import { ScrollView, View } from 'react-native';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import styles from './AppWrapper.styles';
import { UserContext } from './contexts';

const queryClient = new QueryClient();

Sentry.init({
  dsn: process.env['REACT_APP_SENTRY_DSN'],
  environment: process.env['REACT_APP_SENTRY_ENVIRONMENT'],
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const AppWrapper = () => {
  return (
    <View style={styles.appWrapper}>
      <QueryClientProvider client={queryClient}>
        <InitializedApp />
      </QueryClientProvider>
    </View>
  );
};

const InitializedApp = () => {
  const { user, setUser, loading } = useAuthentication();

  return (
    <UserContext.Provider value={{ user, setUser, loading }}>
      <Navbar />
      <View style={styles.screenContainer}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </View>
    </UserContext.Provider>
  );
};

export default AppWrapper;
