import { Pressable, StyleProp, Text, TextStyle } from 'react-native';

import styles from './ButtonLink.styles';

interface ButtonProps {
  label: string;
  onPress?: () => void;
  variant?: 'primary' | 'inverted';
  textStyles?: StyleProp<TextStyle>;
}

export default function ButtonLink({
  label,
  onPress,
  variant = 'primary',
  textStyles,
}: ButtonProps) {
  return (
    <Pressable onPress={onPress}>
      <Text
        style={
          variant === 'primary'
            ? [styles.text, textStyles]
            : [styles.textInverted, textStyles]
        }
      >
        {label}
      </Text>
    </Pressable>
  );
}
