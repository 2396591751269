import { StyleSheet } from 'react-native';

import { theme } from '../../styles/theme';

export default StyleSheet.create({
  container: {
    backgroundColor: theme.palette.white[100],
    height: '100%',
    position: 'relative',
  },

  image: {
    width: '100%',
    height: 200,
    maxHeight: '30%',
  },

  backContainer: {
    position: 'absolute',
    left: 16,
    elevation: 1000,
    backgroundColor: 'white',
    width: 32,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 100,
  },

  contentContainer: {
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    backgroundColor: theme.palette.green[100],
    position: 'absolute',
    width: '100%',
    height: '80%',
    bottom: 0,
    paddingTop: 32,
    padding: 16,
  },
  contentTextContainer: {
    flexGrow: 1,
  },

  overline: {
    ...theme.typography.title7,
    opacity: 0.6,
    color: theme.palette.white[100],
  },

  title: {
    ...theme.typography.title2,
    color: theme.palette.white[100],
    marginTop: 8,
    marginBottom: 16,
  },

  subTitle: {
    ...theme.typography.title5,
    fontSize: 14,
    color: theme.palette.white[100],
    marginBottom: 16,
  },

  paragraph: {
    ...theme.typography.title6,
    fontSize: 14,
    color: theme.palette.white[100],
    marginBottom: 16,
    opacity: 0.6,
  },
});
