import { Text, TouchableOpacity, View } from 'react-native';

import { InputItemProps } from '../../types';
import { getVariantStyles } from './RadioInputItem.styles';

export default function RadioInputItem({
  label,
  selected,
  onPress,
  accessibilityRole,
  variant = 'inverted',
}: InputItemProps) {
  const styles = getVariantStyles(variant);

  return (
    <TouchableOpacity
      onPress={onPress}
      accessibilityRole={accessibilityRole}
      accessibilityState={{ checked: selected }}
      style={{
        ...styles.containerBase,
        ...(selected ? styles.containerSelected : styles.containerUnselected),
      }}
    >
      <View
        style={{
          ...styles.radioBase,
          ...(selected ? styles.radioSelected : styles.radioUnselected),
        }}
      >
        {selected && <View style={styles.radioSelectedIcon} />}
      </View>

      <Text style={styles.text}>{label}</Text>
    </TouchableOpacity>
  );
}
