import { StyleSheet } from 'react-native';

import { theme } from '../../../../styles/theme';

export default StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.green[100],
    paddingBottom: 50,
    paddingHorizontal: 16,
  },

  arrowBackIcon: {
    marginVertical: 16,
  },

  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  },
  videoContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  playButtonContainer: {
    position: 'absolute',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 48,
    borderRadius: 100,
    backgroundColor: theme.palette.white[100],
  },
  contentTextContainer: {
    flexGrow: 1,
    padding: 16,
  },

  title: {
    ...theme.typography.title1,
    color: theme.palette.white[100],
    textAlign: 'center',
    marginTop: 48,
  },
  description: {
    ...theme.typography.title4,
    color: theme.palette.white[100],
    textAlign: 'center',
    fontSize: 14,
    marginTop: 8,
  },
  quote: {
    ...theme.typography.title6,
    color: theme.palette.white[100],
    textAlign: 'center',
    fontSize: 14,
    opacity: 0.5,
    marginTop: 8,
  },

  nextButton: {
    textAlign: 'center',
    borderRadius: 4,
    backgroundColor: theme.palette.white[100],
  },
});
