import { useEffect, useState } from 'react';

import { Text, View } from 'react-native';

import { Modal } from '../../../../components';
import Button from '../../../../components/button/Button';
import useGetValidatePromoCode from '../../../../hooks/queries/useGetValidatePromoCode';
import { PromotionCode } from '../../../../types/payments';
import InputWithButton from '../inputWithButton/InputWithButton';
import styles from './PromoCodeModal.styles';

interface PromoCodeModalProps {
  setPromotionCode: (promotionCode: PromotionCode) => void;
  setPromoCodeError: (error: string) => void;
  promoCodeError: string;
  isOpen: boolean;
  onClose: () => void;
}

export default function PromoCodeModal({
  setPromotionCode,
  isOpen,
  onClose,
  promoCodeError,
  setPromoCodeError,
}: PromoCodeModalProps) {
  const [queryKey, setQueryKey] = useState('');
  const [promoCodeText, setPromoCodeText] = useState('');

  const {
    data: promotionCode,
    error,
    isError,
  } = useGetValidatePromoCode({
    promoCode: promoCodeText || '',
    queryKey,
  });

  useEffect(() => {
    if (promotionCode) {
      setPromotionCode(promotionCode);
    }
  }, [promotionCode]);

  useEffect(() => {
    if (isError) {
      setPromoCodeError(
        (error?.response?.data as any)?.message || 'Invalid promo code'
      );
      return;
    }

    setPromoCodeError('');
  }, [isError]);

  const handleApplyPromoCode = async () => {
    setQueryKey(promoCodeText || '');
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>Enter your promo code</Text>
      </View>

      <View style={styles.inputWithButtonContainer}>
        <InputWithButton
          buttonLabel="Apply"
          text={promoCodeText}
          setText={setPromoCodeText}
          placeholder="Enter promo code here.."
          onButtonPress={handleApplyPromoCode}
          error={promoCodeError}
          successText={promotionCode ? 'Promo code applied' : ''}
        />
      </View>
      <Button label="Close" onPress={onClose} fullWidth={true} />
    </Modal>
  );
}
