import { View } from 'react-native';
import { match } from 'ts-pattern';

import { InputCheckboxButton, InputNumberButton } from '../../items';
import { InputGroupProps } from '../../types';
import styles from './MultipleChoiceInputGroup.styles';

export default function MultipleChoiceInputGroup({
  choices,
  selectionIds,
  setSelectionIds,
  variant = 'default',
  disabled = false,
}: InputGroupProps) {
  const ItemButtonComponent = match(variant)
    .with('numeric', () => InputNumberButton)
    .otherwise(() => InputCheckboxButton);

  return (
    <View style={styles.container}>
      {choices.map(({ name, id }, index) => (
        <ItemButtonComponent
          key={id}
          label={name}
          index={index}
          accessibilityRole="checkbox"
          selected={selectionIds.includes(id)}
          onPress={() => {
            if (!disabled) {
              setSelectionIds(
                selectionIds.includes(id)
                  ? selectionIds.filter((oldId) => oldId !== id)
                  : [...selectionIds, id]
              );
            }
          }}
        />
      ))}
    </View>
  );
}
