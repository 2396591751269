import { Dimensions, StyleSheet } from 'react-native';

import { theme } from '../../../styles/theme';
import { hexToRgbA } from '../../../styles/utils';

const height = Dimensions.get('window').height - 50;

export default StyleSheet.create({
  container: {
    width: '100%',
    height: height,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingBottom: 16,
    paddingHorizontal: 16,
  },
  arrowBackIcon: {
    marginTop: 32,
    marginBottom: 8,
  },

  headingContainer: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'flex-start',
  },

  italicsHighlightTitle: {
    color: theme.palette.green[100],
    ...theme.typography.titleRegular,
    fontStyle: 'italic',
    fontSize: 24,
  },

  title: {
    ...theme.typography.title1,
    color: theme.palette.green[150],
    marginTop: 8,
  },
  subtitle: {
    ...theme.typography.title6,
    color: theme.palette.white[100],
    marginTop: 8,
  },

  illustrationContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  illustration: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 250,
    height: 250,
    backgroundColor: '#357156',
    borderRadius: 999999,
  },
  illustrationText: {
    color: theme.palette.white[100],
  },

  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 8,
  },

  buttonContainer: {
    borderColor: hexToRgbA(theme.palette.black[100], 0.2),
    borderWidth: 1,
    borderRadius: 4,
  },

  termsAndPrivacyPolicy: {
    textAlign: 'center',
  },

  underlineText: {
    textDecorationLine: 'underline',
    paddingHorizontal: 5,
  },

  footer: {
    padding: 30,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
});
