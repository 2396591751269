import { format } from 'date-fns';
import { Text, View } from 'react-native';
import { match } from 'ts-pattern';

import Button from '../../../../components/button/Button';
import {
  DatePicker,
  MultipleChoiceInputGroup,
  SingleChoiceInputGroup,
} from '../../../../components/inputs';
import { SaveQuestionAnswerMutateBody } from '../../../../hooks/mutations';
import {
  Question,
  QuestionnaireAnswers,
} from '../../../../types/questionnaire';
import QuestionLayoutWrapper from '../questionLayoutWrapper';
import styles from './OnboardingQuestion.styles';

interface OnboardingQuestionProps {
  questionIndex: number;
  totalQuestionsCount: number;
  question: Question;
  answer: QuestionnaireAnswers[string] | null;
  onPressNext: (questionId: string) => void;
  onPressBack: () => void;
  onSetAnswer: (answer: SaveQuestionAnswerMutateBody) => void;
}

const renderQuestionInput = ({
  question,
  answer,
  onSetAnswer,
}: OnboardingQuestionProps) => {
  // Currently we only have date as a free response text
  if (question.type === 'free response') {
    return (
      <DatePicker
        label="Date of birth"
        date={new Date(answer?.responseText ? answer.responseText : Date.now())}
        onChangeDate={(date) => {
          onSetAnswer({
            questionId: question.id,
            choiceIds: [question.choices[0].id],
            responseText: date ? format(date, 'yyyy-MM-dd') : '',
          });
        }}
      />
    );
  }

  const InputGroup = match(question)
    .with({ type: 'single choice' }, () => SingleChoiceInputGroup)
    .with({ type: 'multiple choices' }, () => MultipleChoiceInputGroup)
    .exhaustive();

  return (
    <InputGroup
      variant={question.variant}
      choices={question.choices}
      selectionIds={answer?.choiceIds || []}
      setSelectionIds={(selectionIds: string[]) => {
        onSetAnswer({
          questionId: question.id,
          choiceIds: selectionIds,
          responseText: '',
        });
      }}
    />
  );
};

export default function OnboardingQuestion(props: OnboardingQuestionProps) {
  const {
    questionIndex,
    totalQuestionsCount,
    question,
    answer,
    onPressNext,
    onPressBack,
  } = props;

  return (
    <QuestionLayoutWrapper
      title={question.name}
      subtitle={question.subtext}
      currentStep={questionIndex}
      totalSteps={totalQuestionsCount}
      displayProgressBars={true}
      onPressBack={questionIndex !== 0 ? onPressBack : null}
    >
      <View style={styles.choicesContainer}>{renderQuestionInput(props)}</View>

      <View style={styles.footerContainer}>
        <Button
          fullWidth
          color="white"
          disabled={
            !answer ||
            (answer?.choiceIds.length === 0 && answer?.responseText === '')
          }
          onPress={() => onPressNext(question.id)}
          label={questionIndex + 1 === totalQuestionsCount ? 'Finish' : 'Next'}
        />
      </View>
    </QuestionLayoutWrapper>
  );
}
