import { View } from 'react-native';

import styles from './ProgressBars.styles';

interface ProgressBarsProps {
  currentStep: number;
  totalSteps: number;
}

export default function ProgressBars({
  currentStep,
  totalSteps,
}: ProgressBarsProps) {
  return (
    <View style={styles.container}>
      {Array.from({ length: totalSteps }, (_, stepIndex) => (
        <View
          key={stepIndex}
          style={{
            ...styles.step,
            ...(currentStep >= stepIndex ? styles.stepCompleted : {}),
          }}
        />
      ))}
    </View>
  );
}
