import {
  black,
  brown,
  green,
  grey,
  purple,
  red,
  white,
  yellow,
} from './colors';

export const palette = {
  black,
  white,
  grey,
  green,
  yellow,
  red,
  purple,
  brown,
};
