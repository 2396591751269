import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { api } from '../../../api';
import { Questionnaire } from '../../../types/questionnaire';
import { queryKeys } from '../../../utils';

interface UseGetQuestionnaireArgs {
  questionnaireType: 'onboarding' | 'health';
}

export default function useGetQuestionnaire({
  questionnaireType,
}: UseGetQuestionnaireArgs): UseQueryResult<Questionnaire, AxiosError> {
  return useQuery({
    queryKey: queryKeys.questionnaire.getQuestionnaire(questionnaireType),
    queryFn: () =>
      api.get(`/questionnaires/${questionnaireType}`).then((res) => res.data),
    // retry: 0,
    // refetchOnWindowFocus: false,
  });
}
