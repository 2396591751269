import { createContext } from 'react';

import { LoggedInUserResponse } from '~/types/auth';

type UserContextType = {
  user?: LoggedInUserResponse;
  setUser: (user?: LoggedInUserResponse) => void;
  loading: boolean;
};

const UserContext = createContext<UserContextType>({
  user: undefined,
  setUser: () => undefined,
  loading: true,
});

export default UserContext;
