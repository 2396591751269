/**
 * Convert an array of objects to a map of objects using one of the fields as the key.
 * It assumes that the key field is unique, otherwise it will overwrite the previous value.
 * Example:
 *  const x = [{a:1, b:2}, {a:3, b:4}]`
 *  mapArrayWithFieldKey(x, 'a')
 *  > {1: {a:1, b:2}, 3: {a:3, b:4}}
 * @param array Array of objects to map.
 * @param keyField Field to use as the key (must be a property of the objects in the array)
 * @returns A map of objects using the keyField as the key.
 */
export default function useMapArrayWithFieldAsKey<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Record<string, any>,
  K extends keyof T
>(array: T[], keyField: K): Record<T[K], T> {
  return array.reduce(
    (acc, item) => ({ ...acc, [item[keyField]]: item }),
    {} as Record<T[K], T>
  );
}
