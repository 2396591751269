import { Dimensions, Text, View } from 'react-native';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Button from '../../components/button/Button';
import styles from './PaymentErrorScreen.styles';

export default function PaymentErrorScreen() {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const priceIds = searchParams.get('priceIds')?.split(',') || [];
  const promoCode = searchParams.get('promoCode');

  return (
    <View style={styles.container}>
      <View>
        <Text style={styles.title}>Payment Error</Text>
      </View>

      <View style={styles.buttonsContainer}>
        <Button label="Try again" onPress={() => navigate('/')} />
      </View>
    </View>
  );
}
