import { StyleSheet } from 'react-native';

import { theme } from '../../styles/theme';

export default StyleSheet.create({
  text: {
    ...theme.typography.title6,
    fontSize: 14,
    color: theme.palette.green[100],
  },

  textInverted: {
    ...theme.typography.title6,
    fontSize: 14,
    color: theme.palette.white[100],
  },
});
