import { StyleSheet } from 'react-native';

import { theme } from '../../../../styles/theme';

export default StyleSheet.create({
  container: {
    padding: 16,
  },

  rejectContent: {
    flexGrow: 1,
    marginBottom: 16,
  },
  rejectIllustrationContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 150,
  },
  rejectReasonTitle: {
    ...theme.typography.title2,
    marginTop: 32,
  },
  rejectReasonText: {
    ...theme.typography.title6,
    fontSize: 14,
    marginTop: 16,
    opacity: 0.7,
  },
});
