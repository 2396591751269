import { useMutation } from '@tanstack/react-query';

import { api } from '../../../api';
import { getCurrencyCode } from '../../../utils/currencyMap';

interface CreateCheckoutSessionResponse {
  sessionId: string;
}

interface CreateCheckoutSessionMutationPayload {
  priceIds: string[];
  promoCode: string | null;
}

export default function useCreateCheckoutSession() {
  return useMutation({
    mutationFn: (payload: CreateCheckoutSessionMutationPayload) =>
      api
        .post<CreateCheckoutSessionResponse>(
          `/payments/create-checkout-session?currencyCode=${getCurrencyCode()}`,
          payload
        )
        .then((res) => res.data),
  });
}
