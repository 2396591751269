import { StyleSheet } from 'react-native';

import { theme } from '../../../../styles/theme';

export default StyleSheet.create({
  titleContainer: {
    justifyContent: 'flex-start',
    width: '100%',
  },

  title: {
    ...theme.typography.title5,
    color: theme.palette.black[100],
    marginBottom: 16,
    fontSize: 18,
  },

  inputWithButtonContainer: {
    marginBottom: 32,
    width: '100%',
  },
});
