import {
  Dimensions,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import ReactPlayer from 'react-player';

import Button from '../../../../components/button/Button';
import { BackIcon, PlayIcon } from '../../../../icons';
import { theme } from '../../../../styles/theme';
import styles from './CeilStoryEducationScreen.styles';

interface ReduceChronicPainEducationScreenProps {
  onPressNext: () => void;
  onPressBack: () => void;
}

export default function CeilStoryEducationScreen({
  onPressBack,
  onPressNext,
}: ReduceChronicPainEducationScreenProps) {
  const windowWidth = Dimensions.get('window').width;
  const videoSize = Math.max(300, Math.min(400, windowWidth * 0.75));

  return (
    <ScrollView style={styles.container}>
      <TouchableOpacity onPress={onPressBack} style={styles.arrowBackIcon}>
        <BackIcon color={theme.palette.white[100]} size={24} />
      </TouchableOpacity>

      <View style={styles.contentContainer}>
        <View style={styles.videoContainer}>
          <ReactPlayer
            playing={true}
            volume={1}
            width={videoSize}
            height={'100%'}
            style={{ borderRadius: 9999 }}
            controls
            playIcon={<PlayIcon size={48} color={theme.palette.green[100]} />}
            url="https://storage.googleapis.com/revive-assets/ceil-testimonial.mp4"
          />
        </View>

        <View style={styles.contentTextContainer}>
          <Text style={styles.title}>Ceil's story</Text>
          <Text style={styles.description}>Age 59</Text>
          <Text style={styles.description}>
            Arthritis, scoliosis, and Migraines
          </Text>
          <Text style={styles.quote}>
            "I was waking up everyday with pain somewhere for years. 3 weeks
            after starting Revive I could wake up and put my feet on the floor
            with no pain at all."
          </Text>
        </View>
      </View>

      <Button
        fullWidth
        label="Great, next"
        color="white"
        onPress={onPressNext}
      />
    </ScrollView>
  );
}
