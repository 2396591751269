import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { api } from '../../../api';
import { LoggedInUserResponse } from '../../../types/auth';
import { queryKeys } from '../../../utils';

type UseGetMyUserArgs = {
  enabled: boolean;
};

export default function useGetMyUser({
  enabled = true,
}: UseGetMyUserArgs): UseQueryResult<LoggedInUserResponse, AxiosError> {
  return useQuery({
    queryKey: queryKeys.user.getMyUser(),
    queryFn: () => api.get('/users/me').then((res) => res.data),
    enabled,
  });
}
