import { StyleSheet } from 'react-native';

import { theme } from '../../../../styles/theme';
import { hexToRgbA } from '../../../../styles/utils';

export const getVariantStyles = (variant: 'default' | 'inverted') => {
  if (variant === 'default') {
    return StyleSheet.create({
      containerBase: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        columnGap: 24,
        paddingHorizontal: 24,
        paddingVertical: 32,
        borderWidth: 1,
        borderRadius: 4,
      },
      containerUnselected: {
        borderColor: hexToRgbA(theme.palette.black[100], 0.2),
      },
      containerSelected: {
        borderColor: theme.palette.green[100],
        backgroundColor: theme.palette.white[100],
      },

      radioBase: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: 19,
        height: 19,
        borderWidth: 1,
        borderRadius: 9999,
      },
      radioUnselected: {
        borderColor: hexToRgbA(theme.palette.black[100], 0.2),
      },
      radioSelected: {
        borderColor: theme.palette.green[100],
      },
      radioSelectedIcon: {
        width: 11,
        height: 11,
        borderRadius: 9999,
        backgroundColor: theme.palette.green[100],
      },

      text: {
        ...theme.typography.title6,
        color: theme.palette.black[100],
        fontSize: 14,
        flex: 1,
      },
    });
  }

  return StyleSheet.create({
    containerBase: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      columnGap: 24,
      paddingHorizontal: 24,
      paddingVertical: 32,
      borderWidth: 1,
      borderRadius: 4,
    },
    containerUnselected: {
      borderColor: hexToRgbA(theme.palette.white[100], 0.2),
    },
    containerSelected: {
      borderColor: theme.palette.yellow[100],
      backgroundColor: hexToRgbA(theme.palette.black[100], 0.05),
    },

    radioBase: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      width: 19,
      height: 19,
      borderWidth: 1,
      borderRadius: 9999,
    },
    radioUnselected: {
      borderColor: hexToRgbA(theme.palette.white[100], 0.2),
    },
    radioSelected: {
      borderColor: theme.palette.white[100],
    },
    radioSelectedIcon: {
      width: 11,
      height: 11,
      borderRadius: 9999,
      backgroundColor: theme.palette.white[100],
    },

    text: {
      ...theme.typography.title6,
      color: theme.palette.white[100],
      fontSize: 14,
      flex: 1,
    },
  });
};
