import { useContext, useEffect, useRef, useState } from 'react';

import { useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  AbortFlowScreen,
  CeilStoryEducationScreen,
  EnergyLevelsEducationScreen,
  OnboardingQuestion,
  ReduceChronicPainEducationScreen,
} from './components';

import { useGetQuestionsToShow as useMemoQuestionsToShow } from './hooks';

import { loadStripe } from '@stripe/stripe-js';
import { View } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { match } from 'ts-pattern';

import { ErrorScreen, LoadingSpinnerScreen } from '../../components';
import { UserContext } from '../../contexts/';
import {
  SaveQuestionAnswerMutateBody,
  useCreateCheckoutSession,
  useSaveQuestionAnswerMutation,
} from '../../hooks/mutations';
import { Questionnaire, QuestionnaireAnswers } from '../../types/questionnaire';
import { queryKeys } from '../../utils';
import { SelectedPaymentPlan } from '../paymentPlanSelectionScreen/PaymentPlanSelectionScreen';

const QUESTIONNAIRE_TYPE = 'onboarding';

type OnboardingScreenProps = {
  questionnaireQuery: UseQueryResult<Questionnaire, AxiosError<unknown, any>>;
  promoCode: string | null;
  selectedPaymentPlan: SelectedPaymentPlan;
};

const stripePromise = loadStripe(
  process.env['REACT_APP_STRIPE_PUBLISHABLE_KEY'] || ''
);

const OnboardingScreen = ({
  questionnaireQuery,
  selectedPaymentPlan,
  promoCode,
}: OnboardingScreenProps) => {
  const queryClient = useQueryClient();
  const { user } = useContext(UserContext);

  const saveAnswerMutation = useSaveQuestionAnswerMutation({
    questionnaireType: QUESTIONNAIRE_TYPE,
  });

  const {
    mutateAsync: createCheckoutSession,
    data: session,
    isPending,
  } = useCreateCheckoutSession();

  const navigate = useNavigate();

  // This ref holds the server answers and avoid sending the same answer twice if unchanged.
  // The ref is updated when the request succeeds (which means that the server has successfully
  // stored the answer) but doesn't trigger any re-render or re-fetch.
  const answersRef = useRef<QuestionnaireAnswers>({});

  // The state holds the client answers to render the selected options or input texts.
  const [answersState, setAnswersState] = useState<QuestionnaireAnswers>({});

  // When the questionnaire loads, populate the answers in the state.
  useEffect(() => {
    if (questionnaireQuery.data) {
      setAnswersState(questionnaireQuery.data.answers);
      answersRef.current = questionnaireQuery.data.answers;
    }
  }, [questionnaireQuery.data]);

  // Get the questions to show based on the answers. Some questions are skipped if the user
  // answers some questions with specific answers.
  const questionsToShow = useMemoQuestionsToShow({
    questionnaire: questionnaireQuery?.data,
    answers: answersState,
  });

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const currentQuestion = questionsToShow[currentQuestionIndex];

  const [abortFlowReason, setAbortFlowReason] = useState<string | null>(null);

  // This function will be called every time the user selects an answer.
  // Update the state for that question answers.
  const handleSetAnswer = ({
    questionId,
    choiceIds,
    responseText,
  }: SaveQuestionAnswerMutateBody) => {
    setAnswersState((prev) => ({
      ...prev,
      [questionId]: { choiceIds, responseText },
    }));
  };

  const answerHasChanged = (questionId: string) => {
    const responseTextRef = answersRef.current[questionId]?.responseText || '';
    const choiceIdsRef = answersRef.current[questionId]?.choiceIds || [];
    const choiceIdsRefSet = new Set(choiceIdsRef);

    const responseTextState = answersState[questionId]?.responseText || '';
    const choiceIdsState = answersState[questionId]?.choiceIds || [];
    const choiceIdsStateSet = new Set(choiceIdsState);

    return (
      responseTextRef !== responseTextState ||
      choiceIdsRef.length !== choiceIdsState.length ||
      choiceIdsRef.some((id) => !choiceIdsStateSet.has(id)) ||
      choiceIdsState.some((id) => !choiceIdsRefSet.has(id))
    );
  };

  // This function will be called when the user presses the next button.
  // Call the API to save the answer and navigate to the next question.
  const handlePressNext = (questionId: string | null) => {
    if (questionId !== null) {
      // Get the UI answers from the state.
      const choiceIds = answersState[questionId]?.choiceIds || [];
      const responseText = answersState[questionId]?.responseText || '';

      if (answerHasChanged(questionId)) {
        saveAnswerMutation.mutate(
          { questionId, choiceIds, responseText },
          {
            onSuccess: () => {
              answersRef.current = {
                ...answersRef.current,
                [questionId]: { choiceIds, responseText },
              };
            },
          }
        );
      }

      const abortFlowReason = questionnaireQuery.data?.abortFlows?.find(
        (abortFlow) =>
          abortFlow.fromQuestionId === questionId &&
          abortFlow.ifAnswerId === choiceIds[0]
      )?.rejectReason;

      if (abortFlowReason) {
        setAbortFlowReason(abortFlowReason);
        return;
      }

      //! Hardcoded handling for birthdate <18 y.o.
      if (/(\d{4})-(\d{2})-(\d{2})/.test(responseText)) {
        const birthdate = new Date(responseText);

        const age =
          (Date.now() - birthdate.getTime()) / (1000 * 60 * 60 * 24 * 365);

        if (age < 18) {
          setAbortFlowReason(
            'Our health programme is only for people over 18 years of age.'
          );
          return;
        }
      }
    }

    const nextScreenIndex = currentQuestionIndex + 1;

    if (nextScreenIndex < questionsToShow.length) {
      setCurrentQuestionIndex(nextScreenIndex);
    } else {
      queryClient.invalidateQueries(queryKeys.user.getMyUser() as any);
      user?.isSubscriptionActive ? navigate('/download') : goToCheckout();
    }
  };

  // This function will be called when the user presses the arrow back icon.
  // Navigate to the previous question if there is one.
  const handlePressBack = () => {
    if (abortFlowReason) {
      setAbortFlowReason(null);
      return;
    }

    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    } else {
      navigate(-1);
    }
  };

  const goToCheckout = () => {
    if (!selectedPaymentPlan || !Object.values(selectedPaymentPlan)?.length) {
      return navigate('/');
    }

    createCheckoutSession({
      priceIds: Object.values(selectedPaymentPlan)
        .filter((paymentPlan) => paymentPlan)
        .map((price) => price.id),
      promoCode,
    });
  };

  useEffect(() => {
    async function redirectToCheckout() {
      if (!session?.sessionId) {
        return;
      }

      const stripe = await stripePromise;

      await stripe?.redirectToCheckout({
        sessionId: session?.sessionId,
      });
    }

    redirectToCheckout();
  }, [session]);

  return match(questionnaireQuery)
    .with({ isLoading: true }, () => <LoadingSpinnerScreen />)
    .with({ isPending: true }, () => <LoadingSpinnerScreen />)
    .with({ isError: true }, ({ error }) => (
      <ErrorScreen
        errorMessage={error.message}
        errorStatus={error.response?.status}
        ctaText="Try again"
        ctaOnPress={() => {
          navigate('/');
        }}
      />
    ))
    .otherwise(() => {
      if (abortFlowReason !== null) {
        return (
          <AbortFlowScreen
            abortFlowReason={abortFlowReason}
            onPressBack={handlePressBack}
          />
        );
      }

      if (currentQuestion.educationalCard !== null) {
        return match(currentQuestion.educationalCard)
          .with('REDUCE_CHRONIC_PAIN', () => (
            <ReduceChronicPainEducationScreen
              onPressNext={() => handlePressNext(null)}
              onPressBack={handlePressBack}
            />
          ))
          .with('ENERGY_LEVELS', () => (
            <EnergyLevelsEducationScreen
              onPressNext={() => handlePressNext(null)}
              onPressBack={handlePressBack}
            />
          ))
          .with('CEIL_STORY', () => (
            <CeilStoryEducationScreen
              onPressNext={() => handlePressNext(null)}
              onPressBack={handlePressBack}
            />
          ))
          .exhaustive();
      }

      return (
        <OnboardingQuestion
          questionIndex={currentQuestionIndex}
          totalQuestionsCount={questionsToShow.length}
          question={currentQuestion.question}
          answer={answersState[currentQuestion.question.id]}
          onSetAnswer={handleSetAnswer}
          onPressNext={handlePressNext}
          onPressBack={handlePressBack}
        />
      );
    });
};

const OnboardingScreenWrapper = ({
  questionnaireQuery,
  selectedPaymentPlan,
  promoCode,
}: OnboardingScreenProps) => {
  return (
    <View style={{ height: '100%', width: '100%', paddingTop: 0 }}>
      <OnboardingScreen
        questionnaireQuery={questionnaireQuery}
        selectedPaymentPlan={selectedPaymentPlan}
        promoCode={promoCode}
      />
    </View>
  );
};

export default OnboardingScreenWrapper;
