import { default as dailyGoals } from './dailyGoals';
import { default as fitbit } from './fitbit';
import { default as genetics } from './genetics';
import { default as ingredients } from './ingredients';
import { default as mealPlans } from './mealPlans';
import { default as nutrients } from './nutrients';
import { default as payments } from './payments';
import { default as questionnaire } from './questionnaire';
import { default as recipes } from './recipes';
import { default as shoppingList } from './shoppingList';
import { default as user } from './user';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  dailyGoals,
  mealPlans,
  questionnaire,
  recipes,
  user,
  fitbit,
  shoppingList,
  nutrients,
  genetics,
  ingredients,
  payments,
};
