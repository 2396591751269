import { StyleSheet } from 'react-native';

import { theme } from '../../../../styles/theme';

export default StyleSheet.create({
  container: {
    paddingHorizontal: 8,
    width: '100%',
  },

  titleContainer: {
    justifyContent: 'flex-start',
    width: '100%',
  },

  title: {
    ...theme.typography.title5,
    color: theme.palette.black[100],
    marginBottom: 16,
    fontSize: 18,
  },

  descriptionListContainer: {
    marginBottom: 32,
    rowGap: 8,
  },

  descriptionRow: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 16,
  },

  checkIconContainer: {
    width: 24,
    height: 24,
    borderRadius: 12,
    backgroundColor: theme.palette.green[70],
    justifyContent: 'center',
    alignItems: 'center',
  },

  descriptionText: {
    ...theme.typography.titleRegular,
    fontSize: 13,
  },
});
