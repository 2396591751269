import { Text, View } from 'react-native';
import { useNavigate } from 'react-router-dom';

import Button from '../../components/button/Button';
import { HappyIcon } from '../../icons';
import { theme } from '../../styles/theme';
import styles from './StartOnboardingScreen.styles';

export default function StartOnboardingScreen() {
  const navigate = useNavigate();

  return (
    <View style={styles.container}>
      <View style={styles.iconContainer}>
        <HappyIcon color={theme.palette.green[100]} size={60} />
      </View>

      <View style={styles.textContainer}>
        <Text style={styles.title}>Great, let's set up your profile!</Text>
        <Text style={styles.description}>
          We'll ask you a few simple questions to help you get started on your
          journey with Revive.
        </Text>
      </View>

      <Button
        color="white"
        label="Next"
        fullWidth={true}
        onPress={() => navigate('/onboarding')}
      />
    </View>
  );
}
