import { useState } from 'react';

import { OAuthProvider, signInWithPopup } from 'firebase/auth';

import { auth } from '../../../config/firebaseConfig';

const provider = new OAuthProvider('apple.com');

export default function useAuthWithApple() {
  const [error, setError] = useState<string | null>(null);

  const signInWithApple = async () => {
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.log('inside error', (error as any)?.message);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setError((error as any).message);
    }
  };

  return { error, signInWithApple };
}
