const base = ['meal-plans'] as const;

export default {
  list: (filters: Record<string, string | number>) =>
    [...base, 'list', filters] as const,

  completedMealPlanDatesList: (dates: Date[]) =>
    ['completed-meal-plan-dates', dates.sort().toString()] as const,
  create: () => [...base, 'create'] as const,
};
