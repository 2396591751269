import { Text, View } from 'react-native';
import { match } from 'ts-pattern';

import { EmotionSadIcon } from '../../icons';
import { theme } from '../../styles/theme';
import Button from '../button/Button';
import styles from './ErrorScreen.styles';

interface ErrorScreenProps {
  errorMessage: string;
  errorStatus?: number;
  ctaText: string;
  ctaOnPress: () => void;
}

export default function ErrorScreen(props: ErrorScreenProps) {
  const errorText = match(props)
    .with(
      { errorMessage: 'Network Error' },
      () => 'We encountered an error while trying to connect with our server.'
    )
    .otherwise(() => props.errorMessage);

  return (
    <View style={styles.container}>
      <View style={styles.textContainer}>
        <View style={styles.sadFaceContainer}>
          <EmotionSadIcon size={82} fill={theme.palette.green[100]} />
        </View>

        <Text style={styles.textTitle}>Sorry, something went wrong...</Text>
        <Text style={styles.textDescription}>{errorText}</Text>
      </View>

      <Button fullWidth label={props.ctaText} onPress={props.ctaOnPress} />
    </View>
  );
}
