import { palette } from './palette';
import { radius } from './radius';
import { shadows } from './shadows';
import { typography } from './typography';

export const theme = {
  palette,
  radius,
  shadows,
  typography,
};
