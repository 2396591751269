import { StyleSheet } from 'react-native';

import { theme } from '../../../../styles/theme';

export default StyleSheet.create({
  choicesContainer: {
    flexGrow: 1,
    justifyContent: 'center',
    zIndex: 1,
  },

  footerContainer: {
    width: '100%',
    display: 'flex',
    rowGap: 24,
    marginTop: 48,
    marginBottom: 24,
  },
  informationText: {
    ...theme.typography.title6,
    color: theme.palette.white[100],
    textAlign: 'center',
  },
  nextButton: {
    textAlign: 'center',
    borderRadius: 4,
    backgroundColor: theme.palette.white[100],
  },
});
