import { ReactNode } from 'react';

import { Modal as RnModal, Text, View } from 'react-native';

import styles from './Modal.styles';

export type ModalProps = {
  onClose: () => void;
  isOpen: boolean;
  children?: ReactNode;
  showBackgroundWithBackdrop?: boolean;
  showAnimation?: boolean;
};

const Modal = ({
  onClose,
  isOpen,
  children,
  showAnimation = true,
  showBackgroundWithBackdrop = true,
}: ModalProps) => {
  return (
    <RnModal
      visible={isOpen}
      animationType={showAnimation ? 'slide' : undefined}
      presentationStyle="fullScreen"
      transparent={true}
      onRequestClose={onClose}
    >
      <View
        style={
          showBackgroundWithBackdrop
            ? styles.containerWithBackdrop
            : styles.containerWithoutBackdrop
        }
      >
        <View style={styles.container}>{children}</View>
      </View>
    </RnModal>
  );
};

export default Modal;
