import { useMutation } from '@tanstack/react-query';

import { api } from '../../../api';
import { queryKeys } from '../../../utils';

export interface SaveQuestionAnswerMutateBody {
  questionId: string;
  choiceIds: string[];
  responseText: string;
}

interface UseSaveQuestionAnswerMutationArgs {
  questionnaireType: 'onboarding' | 'health';
  onSuccess?: () => void;
}

export default function useSaveQuestionAnswerMutation({
  questionnaireType,
  onSuccess,
}: UseSaveQuestionAnswerMutationArgs) {
  return useMutation({
    mutationKey: queryKeys.questionnaire.saveAnswers(),

    mutationFn: (body: SaveQuestionAnswerMutateBody) =>
      api
        .post(`/questionnaires/${questionnaireType}/answer`, body)
        .then((res) => res.data),

    onSuccess: () => {
      onSuccess?.();
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      // TODO: Handle errors properly
      console.error(error.data);
    },
  });
}
