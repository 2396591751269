import { ReactNode } from 'react';

import { Text, TouchableOpacity, View } from 'react-native';

import styles from './SelectionButton.styles';

interface SelectionButtonProps {
  label?: string;
  onPress?: () => void;
  selected: boolean;
  children?: ReactNode;
}

export default function SelectionButton({
  label,
  onPress,
  selected,
  children,
}: SelectionButtonProps) {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={selected ? styles.selectedContainer : styles.container}
    >
      {label ? (
        <Text style={styles.text}>{label}</Text>
      ) : (
        <View>{children}</View>
      )}
    </TouchableOpacity>
  );
}
