const currencyMap = {
  // United States
  en: 'USD',
  'en-US': 'USD',

  // United Kingdom
  'en-GB': 'GBP',

  // Germany
  de: 'EUR', // Default to EUR for generic 'de'
  'de-DE': 'EUR',

  // Ireland
  'en-IE': 'EUR',

  // Finland
  fi: 'EUR', // Default to EUR for generic 'fi'
  'fi-FI': 'EUR',

  // Norway
  no: 'NOK', // Default to NOK for generic 'no
  'no-NO': 'NOK',
  'nb-NO': 'NOK',

  // Sweden
  sv: 'SEK', // Default to SEK for generic 'sv'
  'sv-SE': 'SEK',

  // Denmark
  da: 'DKK', // Default to DKK for generic 'da'
  'da-DK': 'DKK',

  // Default (if not matched)
  default: 'USD',
};

export const getCurrencyCode = () => {
  const locale = navigator.language as keyof typeof currencyMap;

  return (currencyMap[locale] || 'USD').toLowerCase();
};
