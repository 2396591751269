import { StyleSheet } from 'react-native';

import { theme } from '../../styles/theme';

export default StyleSheet.create({
  container: {
    flexDirection: 'row',
    backgroundColor: theme.palette.white[100],
    padding: 4,
    alignItems: 'center',
    borderRadius: 100,
  },

  focussedTabContainer: {
    backgroundColor: theme.palette.green[100],
    borderRadius: 100,
    borderWidth: 1,
    borderColor: theme.palette.green[100],
    paddingVertical: 11,
    alignItems: 'center',
    ...theme.typography.title6,
  },

  focussedTabText: {
    color: theme.palette.white[100],
    ...theme.typography.title5,
  },

  unfocussedTabContainer: {
    alignItems: 'center',
    ...theme.typography.title6,
    paddingVertical: 11,
  },

  unfocussedTabText: {
    ...theme.typography.title6,
  },
});
