export default function PlayIcon({ size = 18, color = "black", ...props }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.84175 3.93523C8.85139 3.94143 8.86107 3.94765 8.87078 3.9539L18.312 10.0232L18.3395 10.0409C18.6643 10.2497 18.95 10.4334 19.1665 10.6022C19.3894 10.776 19.6278 10.9994 19.7627 11.3189C19.9467 11.7543 19.9467 12.2457 19.7627 12.6811C19.6278 13.0006 19.3894 13.224 19.1665 13.3978C18.95 13.5666 18.6643 13.7503 18.3395 13.9591L18.312 13.9768L8.87078 20.0461L8.84173 20.0648C8.4651 20.3069 8.13966 20.5162 7.86665 20.6583C7.59247 20.801 7.25506 20.941 6.87533 20.9139C6.369 20.8777 5.90332 20.6235 5.5991 20.2171C5.37094 19.9124 5.30629 19.5528 5.27807 19.245C5.24997 18.9385 5.24998 18.5516 5.25 18.1039L5.25 18.0693V5.93066L5.25 5.89614C5.24998 5.44839 5.24997 5.06149 5.27807 4.75498C5.30629 4.44719 5.37094 4.08763 5.5991 3.78287C5.90332 3.37651 6.369 3.12228 6.87533 3.08611C7.25506 3.05899 7.59247 3.19904 7.86665 3.34175C8.13967 3.48385 8.46511 3.69308 8.84175 3.93523ZM6.96782 4.58374C6.90586 4.59178 6.84913 4.62276 6.80886 4.67053C6.80312 4.68751 6.78497 4.74832 6.7718 4.89195C6.75076 5.1215 6.75 5.4385 6.75 5.93066V18.0693C6.75 18.5615 6.75076 18.8785 6.7718 19.1081C6.78497 19.2517 6.80312 19.3125 6.80886 19.3295C6.84913 19.3772 6.90586 19.4082 6.96782 19.4163C6.98521 19.4119 7.04618 19.3943 7.17411 19.3277C7.37858 19.2213 7.64565 19.0505 8.05965 18.7843L17.5008 12.715C17.8614 12.4832 18.0882 12.3365 18.2441 12.215C18.3403 12.1399 18.3755 12.0994 18.3849 12.0877C18.406 12.0311 18.406 11.9688 18.3849 11.9123C18.3755 11.9006 18.3403 11.8601 18.2441 11.785C18.0882 11.6635 17.8614 11.5168 17.5008 11.285L8.05965 5.21566C7.64565 4.94952 7.37858 4.77873 7.17411 4.67231C7.04617 4.60572 6.98521 4.5881 6.96782 4.58374Z"
        fill={color}
        {...props}
      />
    </svg>
  );
}
