import { Dimensions, StyleSheet } from 'react-native';

import { theme } from '../../styles/theme';

const windowWidth = Dimensions.get('window').width;

export default StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 32,
    paddingBottom: 16,
    paddingHorizontal: 16,
    backgroundColor: theme.palette.green[100],
  },

  iconContainer: {
    padding: 20,
    backgroundColor: theme.palette.white[100],
    borderRadius: 300,
    width: 100,
  },

  textContainer: {
    marginBottom: 32,
  },

  title: {
    ...theme.typography.title1,
    fontSize: 18,
    marginTop: 64,
    color: theme.palette.white[100],
  },

  description: {
    ...theme.typography.title6,
    marginTop: 24,
    fontSize: 13,
    color: theme.palette.white[100],
  },
});
