const base = ['genetics'] as const;

export default {
  nutrientNeeds: (nutrientType: string) =>
    [...base, 'nutrient-needs', { nutrientType }] as const,

  reportsList: () => [...base, 'reports-list'] as const,

  getFileUploadUrl: () => [...base, 'file-upload-url'] as const,

  getPdfSignedUrl: (reportId: number) =>
    [...base, 'get-pdf-signed-url', { reportId }] as const,

  getGenomeFileDownloadUrl: () =>
    [...base, 'genome-file-download-url'] as const,
};
