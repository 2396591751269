import { View } from 'react-native';
import { match } from 'ts-pattern';

import { InputNumberButton, InputRadioButton } from '../../items';
import { InputGroupProps } from '../../types';
import styles from './SingleChoiceInputGroup.styles';

export default function SingleChoiceInputGroup({
  choices,
  selectionIds,
  setSelectionIds,
  variant = 'default',
  disabled = false,
}: InputGroupProps) {
  const ItemButtonComponent = match(variant)
    .with('numeric', () => InputNumberButton)
    .otherwise(() => InputRadioButton);

  return (
    <View style={styles.container} accessibilityRole="radiogroup">
      {choices.map(({ id, name }, index) => (
        <ItemButtonComponent
          key={id}
          label={name}
          index={index}
          accessibilityRole="radio"
          selected={id === selectionIds[0]}
          onPress={() => {
            if (!disabled) {
              setSelectionIds([id]);
            }
          }}
        />
      ))}
    </View>
  );
}
