import { useContext } from 'react';

import { Image, Text, View } from 'react-native';
import { useNavigate } from 'react-router-dom';

import Button from '../../components/button/Button';
import { auth } from '../../config/firebaseConfig';
import { UserContext } from '../../contexts';
import styles from './DownloadAppScreen.styles';

const downloadAppImageUri = `https://res.cloudinary.com/dvzgpiimw/image/upload/v1719416396/app-assets/download-app-screen-image_tebe2s.png`;

export default function DownloadAppScreen() {
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();

  // const handleLogout = async () => {
  //   try {
  //     await auth.signOut();
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setUser(undefined);
  //     navigate('/');
  //   }
  // };

  return (
    <View style={styles.container}>
      <Text style={styles.title}>All signed up!</Text>
      <Text style={styles.subtitle}>You’re ready to start your journey</Text>

      <Text style={styles.description}>
        Welcome to our world. A world in which we remove the guesswork for you.
        We give you nutrition advice based on your unique DNA
      </Text>

      <View style={styles.buttonsContainer}>
        <a
          href="https://revivedna.onelink.me/cMnK/w4v007l6"
          target="_blank"
          rel="noreferrer noopener"
          style={{ textDecoration: 'none', marginBottom: 16 }}
        >
          <Button label="Click here to open the app" />
        </a>
      </View>

      <Image style={styles.image} source={{ uri: downloadAppImageUri }} />

      <Text style={styles.copyrightText}>
        Copyright ©Progress Health and Wellness LTD 2024
      </Text>
    </View>
  );
}
