import ReactDatePicker from 'react-datepicker';
import { Text, TextInput, TouchableOpacity, View } from 'react-native';

import 'react-datepicker/dist/react-datepicker.css';
import './ReactDatePicker.css';
import { theme } from '../../../styles/theme';
import { hexToRgbA } from '../../../styles/utils';
import styles from './DatePicker.styles';

interface DatePickerProps {
  label?: string;
  date: Date | undefined;
  onChangeDate: (date: Date | null) => void;
}

export interface PickerProps {
  showPicker: boolean;
  setShowPicker: (showPicker: boolean) => void;
  date: Date | null;
  onChangeDate: (date: Date | null) => void;
}

export default function DatePicker({
  label,
  date,
  onChangeDate,
}: DatePickerProps) {
  return (
    <View style={styles.container}>
      {label && <Text style={styles.label}>{label}</Text>}

      <ReactDatePicker
        selected={date}
        onChange={(date) => onChangeDate(date)}
        required={true}
        showYearDropdown={true}
        scrollableYearDropdown={true}
        showMonthDropdown={true}
        yearDropdownItemNumber={100}
        maxDate={new Date()}
        customInput={
          <TouchableOpacity style={styles.inputContainer}>
            <TextInput
              editable={false}
              placeholder="Pick a date"
              value={date?.toDateString()}
              style={styles.input}
              showSoftInputOnFocus={false}
              focusable={false}
              placeholderTextColor={hexToRgbA(theme.palette.white[100], 0.7)}
              onPressIn={() => console.log('press in')}
            />
          </TouchableOpacity>
        }
        popperPlacement="bottom-end"
        portalId="root"
      />
    </View>
  );
}
