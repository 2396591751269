import { ReactNode } from 'react';

import { Text, TouchableOpacity, View } from 'react-native';

import { Modal } from '../../../../components';
import Button from '../../../../components/button/Button';
import { CheckIcon } from '../../../../icons';
import { theme } from '../../../../styles/theme';
import styles from './ProductDescriptionSheet.styles';

interface SelectionButtonProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  descriptionList: string[];
}

export default function ProductDescriptionSheet({
  title,
  isOpen,
  onClose,
  descriptionList,
}: SelectionButtonProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} showAnimation={false}>
      <View style={styles.container}>
        <View style={styles.titleContainer}>
          <Text style={styles.title}>{title}</Text>
        </View>
        <View style={styles.descriptionListContainer}>
          {descriptionList.map((description, index) => (
            <View style={styles.descriptionRow} key={index}>
              <View style={styles.checkIconContainer}>
                <CheckIcon color={theme.palette.green[100]} />
              </View>
              <Text style={styles.descriptionText}>{description}</Text>
            </View>
          ))}
        </View>
        <Button label="Got it, thanks" onPress={onClose} fullWidth={true} />
      </View>
    </Modal>
  );
}
