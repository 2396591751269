import { useContext, useEffect } from 'react';

import { Linking, Text, TouchableOpacity, View } from 'react-native';
import { useNavigate } from 'react-router-dom';

import Button from '../../../components/button/Button';
import ButtonLink from '../../../components/buttonLink';
import { UserContext } from '../../../contexts';
import {
  useAuthWithApple,
  useAuthWithGoogle,
} from '../../../hooks/authentication';
import { AppleLogoIcon, BackIcon, GoogleIcon } from '../../../icons';
import { theme } from '../../../styles/theme';
import styles from './AuthScreen.styles';

export default function AuthScreen() {
  const { user } = useContext(UserContext);

  const navigate = useNavigate();

  const { signInWithGoogle } = useAuthWithGoogle();
  const { signInWithApple } = useAuthWithApple();

  const handlePressGoogleSignin = async () => {
    await signInWithGoogle();
  };

  const handlePressAppleSignin = async () => {
    await signInWithApple();
  };

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [navigate, user]);

  return (
    <View style={styles.container}>
      <View>
        <TouchableOpacity
          onPress={() => navigate(-1)}
          style={styles.arrowBackIcon}
        >
          <BackIcon color={theme.palette.white[100]} size={24} />
        </TouchableOpacity>

        <View style={styles.headingContainer}>
          <Text style={styles.title}>Sign in your Revive account</Text>
        </View>
      </View>

      <View>
        <View style={styles.buttonsContainer}>
          <View style={styles.buttonContainer}>
            <Button
              label="Sign in with Google"
              color="white"
              fullWidth
              icon={<GoogleIcon />}
              onPress={handlePressGoogleSignin}
            />
          </View>

          <View style={styles.buttonContainer}>
            <Button
              label="Sign in with Apple"
              color="white"
              fullWidth
              icon={<AppleLogoIcon />}
              onPress={handlePressAppleSignin}
            />
          </View>
        </View>
      </View>

      <View style={styles.footer}>
        <Text style={styles.termsAndPrivacyPolicy}>
          By using Revive you agree to our
        </Text>
        <ButtonLink
          label="Terms"
          onPress={() =>
            Linking.openURL('https://revivedna.com/terms-and-conditions')
          }
          textStyles={styles.underlineText}
        />
        <Text style={styles.termsAndPrivacyPolicy}>and</Text>
        <ButtonLink
          label="Privacy Policy"
          onPress={() =>
            Linking.openURL('https://revivedna.com/privacy-policy')
          }
          textStyles={styles.underlineText}
        />
      </View>
    </View>
  );
}
