import { StyleSheet } from 'react-native';

import { theme } from '../../../styles/theme';
import { hexToRgbA } from '../../../styles/utils';

export default StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 8,
  },

  label: {
    ...theme.typography.title6,
    color: theme.palette.white[100],
  },

  inputContainer: {
    borderWidth: 1,
    borderRadius: 4,
    borderColor: hexToRgbA(theme.palette.white[100], 0.2),
  },

  input: {
    ...theme.typography.title6,
    color: theme.palette.white[100],
    borderColor: 'transparent',
    padding: 16,
  },
});
