import { StyleSheet } from 'react-native';

import { theme } from '../../styles/theme';
import { hexToRgbA } from '../../styles/utils';

export default StyleSheet.create({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    columnGap: 8,
  },

  step: {
    height: 4,
    maxWidth: 16,
    flex: 1,
    borderRadius: 1,
    backgroundColor: hexToRgbA(theme.palette.white[100], 0.2),
  },
  stepCompleted: {
    backgroundColor: theme.palette.yellow[100],
  },
});
