import EducationCard from '../../../../components/educationCard';

const image =
  'https://thumbor.forbes.com/thumbor/fit-in/900x510/https://www.forbes.com/health/wp-content/uploads/2022/01/foods_to_eat_to_lose_weight.jpeg';

interface ReduceChronicPainEducationScreenProps {
  onPressNext: () => void;
  onPressBack: () => void;
}

export default function ReduceChronicPainEducationScreen({
  onPressBack,
  onPressNext,
}: ReduceChronicPainEducationScreenProps) {
  return (
    <EducationCard
      title="How we improve energy levels"
      subTitle="The first stage of your journey is focused on reducing systemic inflammation in your body."
      paragraphs={[
        'When our bodies are fighting an infection or we are suffering with a chronic illness it uses up a lot of energy, leaving you feeling tired and lethargic.',
        'By targeting macrophages beneath the gut lining we can switch them to the anti-inflammatory state which helps resolve pain signalling.',
        'By further strengthening the gut through targeted nutritional interventions we then help your body create more energy via butyrate production in the large intestine and an improvement in the powerhouse of your cells, the mitochondria.',
      ]}
      backdropImage={image}
      nextButtonText="Great, next"
      onPressBack={onPressBack}
      onPressNext={onPressNext}
    />
  );
}
