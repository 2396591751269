import { Dimensions, StyleSheet } from 'react-native';

const screenContainerHeight = Dimensions.get('window').height - 61;

export default StyleSheet.create({
  appWrapper: {
    textAlign: 'center',
    height: '100%',
    width: '100%',
    alignItems: 'center',
  },

  screenContainer: {
    maxWidth: 400,
    width: '100%',
    marginTop: 10,
    height: screenContainerHeight,
  },
});
