const base = ['payment'] as const;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  validatePromoCode: (promoCode: string) =>
    [...base, 'validate-promoCode', promoCode] as const,

  getPaymentPlans: () => [...base, 'payment-plans'] as const,

  getUpcomingInvoice: () => [...base, 'upcoming-invoice'] as const,
};
