export default function EmotionSadIcon({ size = 18, ...props }) {
  return (
    <svg width={size} height={size} viewBox="0 0 80 80" fill="none">
      <path
        id="Union"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M40 9.25C23.0172 9.25 9.25 23.0172 9.25 40C9.25 56.9828 23.0172 70.75 40 70.75C56.9828 70.75 70.75 56.9828 70.75 40C70.75 23.0172 56.9828 9.25 40 9.25ZM10.75 40C10.75 23.8457 23.8457 10.75 40 10.75C56.1543 10.75 69.25 23.8457 69.25 40C69.25 56.1543 56.1543 69.25 40 69.25C23.8457 69.25 10.75 56.1543 10.75 40ZM27.2647 53.7838C27.0159 54.115 26.5458 54.1818 26.2146 53.933C25.8834 53.6842 25.8166 53.2141 26.0654 52.8829C29.2416 48.6543 34.301 45.9167 39.9996 45.9167C45.6982 45.9167 50.7576 48.6543 53.9338 52.8829C54.1826 53.2141 54.1158 53.6842 53.7846 53.933C53.4534 54.1818 52.9832 54.115 52.7345 53.7838C49.8293 49.9161 45.2065 47.4167 39.9996 47.4167C34.7927 47.4167 30.1698 49.9161 27.2647 53.7838ZM33.3333 33.3333C33.3333 35.1743 31.8409 36.6667 30 36.6667C28.159 36.6667 26.6667 35.1743 26.6667 33.3333C26.6667 31.4924 28.159 30 30 30C31.8409 30 33.3333 31.4924 33.3333 33.3333ZM50 36.6667C51.8409 36.6667 53.3333 35.1743 53.3333 33.3333C53.3333 31.4924 51.8409 30 50 30C48.159 30 46.6667 31.4924 46.6667 33.3333C46.6667 35.1743 48.159 36.6667 50 36.6667Z"
        {...props}
      />
    </svg>
  );
}
