import { Text, TouchableOpacity, View } from 'react-native';

import styles from './TabBar.styles';

export type TabBarProps = {
  activeTabIndex: number;
  setActiveTabIndex: React.Dispatch<React.SetStateAction<number>>;
  tabLabels: string[];
};

const TabBar = ({
  activeTabIndex,
  setActiveTabIndex,
  tabLabels,
}: TabBarProps) => {
  return (
    <View style={styles.container}>
      {tabLabels.map((tabLabel, index) => {
        const isFocused = activeTabIndex === index;

        const onPress = () => {
          setActiveTabIndex(index);
        };

        return (
          <TouchableOpacity
            key={tabLabel}
            accessibilityRole="button"
            accessibilityState={isFocused ? { selected: true } : {}}
            accessibilityLabel={tabLabel}
            testID={tabLabel}
            onPress={onPress}
            style={{
              flex: 1,
            }}
          >
            {isFocused ? (
              <View style={styles.focussedTabContainer}>
                <Text style={styles.focussedTabText}>{tabLabel as any}</Text>
              </View>
            ) : (
              <View style={styles.unfocussedTabContainer}>
                <Text style={styles.unfocussedTabText}>{tabLabel as any}</Text>
              </View>
            )}
          </TouchableOpacity>
        );
      })}
    </View>
  );
};

export default TabBar;
